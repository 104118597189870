<article>
  <p class="title">Termos de Uso da Plataforma MZIC</p>
  <p class="title">Última atualização: 08 de Agosto de 2024.</p>

  <p class="context">
    Bem-vindo(a) ao
    <a target="_blank" href="https://mzic.io">https://mzic.io.</a>
    Por favor, leia atentamente estes Termos de Serviço (os
    <b>“Termos”</b>
    ). Este é um acordo entre você (doravante
    <b>“Usuário"</b>
    ,
    <b>“você”</b>
    ou termos de significado similar) e MZIC LLC. (a
    <b>“MZIC"</b>
    ,
    <b>“nós”</b>
    ou termos de significado similar) (você e MZIC serão cada um uma
    <b>“Parte”</b>
    deste acordo e, coletivamente, as
    <b>“Partes”</b>
    ). Estes Termos regem o uso do site da MZIC localizado em
    <a target="_blank" href="https://mzic.io">https://mzic.io</a>
    (o
    <b>“Site”</b>
    ), MZIC for Artists, e todas as ferramentas relacionadas, aplicativos móveis
    (
    <b>“App”</b>
    ), aplicativos web, aplicativos descentralizados, contratos inteligentes,
    APIs ou outras plataformas, aplicativos ou serviços oferecidos ou associados
    à MZIC (coletivamente, a
    <b>“Plataforma MZIC”</b>
    ). Para tornar estes Termos mais fáceis de ler, o Site, MZIC for Artists,
    nossos serviços, App e Plataforma MZIC são coletivamente chamados de
    <b>“Serviços.”</b>
  </p>

  <p class="paragraph">
    <span>1</span>
    Aceitação dos Termos
  </p>

  <p class="context">
    <span>1.1</span>
    Ao continuar a navegar, acessar e usar nossos Serviços, ou ao obter, manter
    ou possuir um token de direitos de propriedade sobre conteúdos (“Token de
    fração”), você reconhece que leu e aceita os termos e condições
    estabelecidos abaixo. Se você não aceitar, deve cessar imediatamente o
    acesso e uso de nossos serviços.
  </p>

  <p class="context">
    <span>1.2</span>
    Se você optar por aceitar estes Termos, deve fazê-lo como eles são
    apresentados a você – não aceitaremos alterações. Se quaisquer alterações
    futuras forem inaceitáveis para você, deverá descontinuar o uso dos
    Serviços. O seu uso contínuo dos Serviços agora, ou após a publicação e
    aviso razoável de tais alterações, indicará sua aceitação destes Termos e de
    quaisquer alterações.
  </p>

  <p class="paragraph">
    <span>2</span>
    Termos e Políticas Adicionais
  </p>

  <p class="context">
    <span>2.1</span>
    Seu uso dos Serviços está sujeito a termos e políticas adicionais que podem
    ser publicados nos Serviços de tempos em tempos e que incluirão o seguinte:
  </p>

  <p class="context">
    <span>2.2</span>
    <b class="underline">Política de Privacidade:</b>
    Por favor, leia nossa Política de Privacidade disponível em
    <a target="_blank" href="https://legal.mzic.io/pt-br/privacy">
      https://legal.mzic.io/privacy
    </a>
    , que também rege o uso dos Serviços. Ao enviar dados pessoais através de
    nossos Serviços, você concorda com os termos de nossa Política de
    Privacidade e consente expressamente com a coleta, uso e divulgação de seus
    dados pessoais de acordo com a Política de Privacidade.
  </p>

  <p class="context">
    <span>2.3</span>
    <b class="underline">Riscos associados:</b>
    O uso dos Serviços, incluindo a negociação de tokens de fração, pode
    acarretar riscos significativos. Alguns desses riscos são discutidos aqui,
    mas você deve revisar nossa página sobre Riscos Associados aos à tokens de
    fração, disponível neste em
    <a target="_blank" href="https://legal.mzic.io/pt-br/risks">
      https://legal.mzic.io/risks
    </a>
    para obter mais informações sobre esses riscos.
  </p>

  <p class="context">
    <span>2.4</span>
    <b class="underline">Termos do Artista e Termos do Usuário:</b>
    Se você é um Artista ou um Usuário, estará sujeito aos seguintes termos,
    conforme aplicável:
  </p>

  <p class="context">
    <span>2.4.1</span>
    <b class="underline">Artistas:</b>
    Você estará sujeito aos termos do produto de venda dos direitos de
    propriedade regidos no Contrato de Agenciamento de Transferência de Direitos
    de Propriedade sobre Conteúdos, termos de distribuição digital regidos nos
    Termos de Distribuição Digital e Contrato de Repartição de Royalties de
    Distribuição, de acordo com os produtos que você contrate da MZIC.
  </p>

  <p class="context">
    <span>2.4.2</span>
    <b class="underline">Usuários:</b>
    Você estará sujeito aos termos do produto de aquisição dos direitos de
    propriedade regidos no Contrato de Aquisição de Direitos de Propriedade
    sobre Conteúdos, de acordo com os produtos que você contrate da MZIC.
  </p>

  <p class="context">
    <span>2.5</span>
    <b class="underline">Provedores de Serviços Terceirizados:</b>
    Nós fazemos parcerias com terceiros para auxiliar em pagamentos, transações,
    verificação de identidade, prevenção de fraude e outras funções legais e de
    conformidade. Ao usar nossos Serviços, você concorda com:
  </p>

  <p class="context">
    <span>2.5.1</span>
    ZOOP TECNOLOGIA E INSTITUIÇÃO DE PAGAMENTO S.A.
    <a
      target="_blank"
      href="https://blog.zoop.com.br/termos-e-condicoes-de-uso-do-sistema-zoop">
      Termos de Uso
    </a>
    e
    <a target="_blank" href="https://www.zoop.com.br/politicas/privacidade">
      Política de Privacidade.
    </a>
  </p>

  <p class="context">
    <span>2.5.2</span>
    Plaid Technologies, Inc.
    <a
      target="_blank"
      href="https://plaid.com/legal/#end-user-services-agreement-us">
      Termos de Uso
    </a>
    e
    <a target="_blank" href="https://plaid.com/legal/#end-user-privacy-policy">
      Política de Privacidade.
    </a>
  </p>

  <p class="paragraph">
    <span>3</span>
    Registro e Gestão de Contas
  </p>

  <p class="context">
    <span>3.1</span>
    <b class="underline">Criação e Configuração da Conta:</b>
    Para fazer uso da Plataforma MZIC e interagir com seus serviços, o usuário
    deve primeiro criar uma conta, fornecendo informações pessoais precisas e
    atuais, como nome civil, endereço de e-mail e número de telefone. Após a
    criação da conta, será necessário concluir o processo de verificação (AML e
    KYC), podendo ser solicitado uma identificação emitida pelo governo.
  </p>

  <p class="context">
    <span>3.2</span>
    <b class="underline">Segurança da Conta:</b>
    Os Usuários são responsáveis por manter a segurança de sua conta e da Conta
    Digital Inteligente associada, incluindo a confidencialidade de suas
    credenciais de login. Qualquer atividade suspeita ou violação de segurança
    deve ser reportada imediatamente. Os Usuários também devem garantir que seus
    dispositivos estejam seguros, mantendo o sistema operacional, software e
    proteções antivírus atualizados.
  </p>

  <p class="context">
    <span>3.3</span>
    <b class="underline">Conta Digital Inteligente:</b>
    Como parte do compromisso da Plataforma MZIC com a descentralização e o
    controle do Usuário, a Plataforma MZIC adotou a tecnologia de Smart Accounts
    baseada no padrão ERC-4337. Geramos uma Conta Digital Inteligente para cada
    usuário após a criação da conta na Plataforma MZIC. Esta conta serve como um
    repositório para quaisquer Tokens de Fração que um Usuário possa deter.
    Todas as transações de tokens são realizadas através desta Conta Digital
    Inteligente, que representa o endereço na blockchain onde os tokens serão
    armazenados e transitados. A MZIC age apenas como assinante das transações
    que o Usuário realiza, não sendo custodiante dos tokens.
  </p>

  <p class="paragraph">
    <span>4</span>
    Conduta e Obrigações do Usuário
  </p>

  <p class="context">
    <span>4.1</span>
    <b class="underline">Código de Conduta:</b>
    O usuário usará a Plataforma MZIC e seus serviços apenas para fins legítimos
    e legais. O Usuário não usará a Plataforma MZIC para se envolver em
    quaisquer atividades fraudulentas ou maliciosas, incluindo, mas não limitado
    a, falsificação de identidade, deturpação, lavagem de dinheiro ou outras
    formas de comportamento enganoso.
  </p>

  <p class="context">
    <span>4.2</span>
    <b class="underline">Conformidade com as Leis:</b>
    Como usuário da Plataforma MZIC, o Usuário é obrigado a cumprir todas as
    leis, regulamentos e regras aplicáveis, incluindo, mas não limitado àqueles
    relacionados a transações financeiras, direitos autorais, conduta on-line e
    tributação.
  </p>

  <p class="paragraph">
    <span>5</span>
    Serviços da Plataforma MZIC
  </p>

  <p class="context">
    A MZIC oferece dois principais produtos: o Marketplace de Direitos de
    Propriedade sobre Conteúdos e o MZIC For Artists. Cada um deles tem escopos
    específicos e oferece diferentes funcionalidades aos seus usuários.
  </p>

  <p class="context">
    <span>5.1</span>
    <b>Marketplace de Direitos de Propriedade sobre Conteúdos</b>
  </p>

  <p class="context">
    <span>5.1.1</span>
    <b class="underline">Tokens de Música e Tokens de Fração:</b>
    A Plataforma MZIC utiliza a tecnologia blockchain para criar tokens
    exclusivos que representam direitos sobre Conteúdos. Esses tokens permitem a
    digitalização e fracionamento da propriedade de direitos sobre Conteúdos,
    fornecendo aos Usuários e aos Detentores de Direitos uma nova maneira de
    monetizar seu trabalho e oferecendo aos Usuários e aos Detentores de
    Direitos uma oportunidade única de investir em direitos sobre Conteúdos.
  </p>

  <p class="context">
    <span>5.1.2</span>
    <b class="underline">Processamento de Transações e Taxas:</b>
    Todas as transações na Plataforma MZIC, incluindo a aquisição ou disposição
    de Tokens de Fração, são processadas através da blockchain Polygon. No
    entanto, os pagamentos reais dessas transações são processados fora da
    Polygon através de serviços de Processamento de Pagamentos de terceiros.
  </p>

  <p class="context">
    <span>5.1.3</span>
    <b class="underline">Intermediação da Plataforma MZIC:</b>
    A Plataforma da MZIC serve como um mercado para a aquisição, disposição e
    negociação de Tokens de Fração associados a Tokens de Música específicos.
    Essas transações permitem que os Usuários adquiram ou alienem sua
    participação nos Conteúdos específicos sujeitos aos termos estabelecidos
    nestes Termos de Uso e no Contrato de Aquisição de Direitos sobre Conteúdos.
  </p>

  <p class="context">
    <span>5.1.4</span>
    <b class="underline">Arrecadação e Distribuição de Royalties:</b>
    A Plataforma MZIC arrecada Royalties dos DSPs em nome dos Detentores de
    Direitos. A Plataforma MZIC mantém o status de reivindicante de direitos
    autorais patrimoniais para fins de conformidade regulatória e cobrança de
    Royalties.
  </p>

  <p class="context">
    <span>5.2</span>
    <b>MZIC For Artists</b>
  </p>

  <p class="context">
    <span>5.2.1</span>
    <b class="underline">Venda de Direitos Fonográficos:</b>
    A MZIC permite que os artistas criem ofertas de venda de direitos
    fonográficos, possibilitando a monetização de suas obras diretamente para
    investidores e fãs.
  </p>

  <p class="context">
    <span>5.2.2</span>
    <b class="underline">Distribuição Digital:</b>
    A MZIC oferece serviços de distribuição digital, permitindo que os artistas
    distribuam suas músicas em diversos DSPs (Digital Service Providers) de
    forma eficiente e automatizada.
  </p>

  <p class="context">
    <span>5.2.3</span>
    <b class="underline">Repartição de Royalties:</b>
    A MZIC facilita a repartição de royalties entre os artistas e outros
    detentores de direitos, garantindo uma distribuição justa e transparente dos
    rendimentos gerados pelas obras.
  </p>

  <p class="paragraph">
    <span>6</span>
    <b class="underline">Direitos de Propriedade Intelectual</b>
  </p>

  <p class="context">
    <span>6.1</span>
    <b class="underline">Propriedade Intelectual – Plataforma MZIC:</b>
    Todos os direitos relativos à Plataforma MZIC (excluindo Conteúdo do
    Usuário) são e permanecerão propriedade exclusiva da Plataforma MZIC e seus
    proprietários. A Plataforma MZIC é protegida pela legislação de Direitos
    Autorais, Direitos sobre Softwares, Propriedade Industrial e outras leis do
    Brasil e de Tratados Internacionais.
  </p>

  <p class="context">
    <span>6.2</span>
    <b class="underline">
      Conteúdo do Usuário e Violação de Direitos Autorais:
    </b>
    Quando o Usuário carrega, envia, armazena, envia ou recebe Conteúdo do
    Usuário para ou através da Plataforma MZIC, o Usuário concede à Plataforma
    MZIC uma licença mundial, isenta de royalties, perpétua, irrevogável, não
    exclusiva, transferível e sublicenciável para usar, copiar, modificar, criar
    trabalhos derivados, distribuir, exibir publicamente, executar publicamente
    e de outra forma explorar o Conteúdo do Usuário no todo ou em parte para
    fins de operação, desenvolver, fornecer, promover e melhorar a Plataforma
    MZIC e pesquisar e desenvolver novas funcionalidades.
  </p>

  <p class="paragraph">
    <span>7</span>
    <b>Limitações de Serviço e Isenções de Responsabilidade</b>
  </p>

  <p class="context">
    <span>7.1</span>
    <b class="underline">Disponibilidade do Serviço:</b>
    A MZIC fará esforços razoáveis para fornecer os serviços da Plataforma MZIC
    e disponibilizar a Plataforma MZIC aos Usuários 24 horas por dia, 7 dias por
    semana, mas não pode garantir uma operação ininterrupta ou impecável.
  </p>

  <p class="context">
    <span>7.2</span>
    <b class="underline">Limitações de Responsabilidade:</b>
    A MZIC não é uma consultora de investimentos e não fornece qualquer forma de
    aconselhamento de investimento. Todas as informações e recursos fornecidos
    na Plataforma MZIC são estritamente para fins informativos e não devem ser
    interpretados como aconselhamento de investimento ou recomendação para se
    envolver em qualquer forma de atividade de investimento.
  </p>

  <p class="paragraph">
    <span>8</span>
    <b>Resolução de Litígios e Legislação Aplicável</b>
  </p>

  <p class="context">
    <span>8.1</span>
    <b class="underline">Legislação Aplicável:</b>
    Estes Termos de Uso e seu uso da Plataforma MZIC serão regidos e
    interpretados de acordo com as leis do Brasil.
  </p>

  <p class="context">
    <span>8.2</span>
    <b class="underline">Resolução de Disputas e Aspectos Legais:</b>
    Quaisquer dúvidas relativas aos aspectos legais da Plataforma MZIC podem ser
    comunicadas à
    <a href="mailto:legal@mzic.io.">legal&#64;mzic.io.</a>
  </p>

  <p class="paragraph">
    <span>9</span>
    <b>Alterações ao Contrato</b>
  </p>

  <p class="context">
    <span>9.1</span>
    <b class="underline">Modificação do Contrato:</b>
    A MZIC reserva-se o direito a seu exclusivo critério de modificar ou
    substituir estes Termos de Uso a qualquer momento. Se uma revisão for de
    natureza essencial, a Plataforma MZIC fornecerá pelo menos 30 (trinta) dias
    de aviso prévio para que quaisquer novos termos entrem em vigor.
  </p>

  <p class="context">
    <span>9.2</span>
    <b class="underline">Notificação do Usuário:</b>
    A MZIC se esforçará para notificá-lo de quaisquer alterações aos Termos de
    Uso enviando um e-mail para o endereço que o Usuário forneceu no momento do
    registro, fornecendo aviso através da Plataforma MZIC ou através de outros
    canais de comunicação apropriados.
  </p>

  <p class="paragraph">
    <span>10</span>
    <b>Informações de Contato</b>
  </p>

  <p class="context">
    Se o Usuário tiver alguma dúvida sobre estes Termos de Uso ou se desejar
    denunciar violações destes Termos de Uso, entre em contato conosco em:
  </p>

  <p class="title">
    MZIC LLC.
    <br />
    13537 Kitty Fork Road, 32828 Orlando, FL, EUA.
    <br />
    <a href="mailto:legal@mzic.io.">legal&#64;mzic.io.</a>
  </p>
</article>
