<p class="title">POLÍTICAS DE PRIVACIDADE DA PLATAFORMA MZIC</p>
<p class="context">Última atualização: 20 de fevereiro de 2024.</p>

<p class="title">1. INTRODUÇÃO</p>
<p class="context">
  A Plataforma MZIC é aplicativo de propriedade da MZIC LLC, pessoa jurídica de
  direito privado estabelecida segundo as leis dos Estados Unidos da América
  (“EUA”) com sede na 13537 Kitty Fork Road 32828 Orlando FL EUA inscrita no
  Employer Identification Number sob o nº 93-1940892 junto ao Internal Revenue
  Service dos EUA, neste ato representada na forma de seus atos constitutivos
  (“Empresa”). A Empresa preza pelo cuidado com a privacidade, com as
  informações e com os dados dos usuários da Plataforma MZIC. Portanto, o
  presente documento contendo as Políticas de Privacidade da Plataforma MZIC tem
  o objetivo de esclarecer ao usuário da Plataforma MZIC quais informações são
  coletadas, como e por quem são tratadas, como são compartilhadas e como a
  segurança dessas informações é garantida, a fim de manter uma relação de
  absoluta transparência. A EMPRESA DECLARA QUE NÃO COLETA NEM FAZ TRATAMENTO DE
  DADOS PESSOAIS SENSÍVEIS.
</p>

<p class="title">Aplicabilidade</p>
<p class="context">
  As Políticas de Privacidade da Plataforma MZIC se aplicam a todos os titulares
  (pessoas naturais) que interagem com a Plataforma MZIC.
</p>

<p class="title">2. DEFINIÇÕES LEGAIS</p>
<p class="context">
  Para os fins deste documento contendo as Políticas de Privacidade da
  Plataforma MZIC, são usadas as seguintes definições nos termos da Lei Geral de
  Proteção de Dados (Lei 13.709/2018 - “LGPD”): “Agentes de tratamento”: o
  controlador e o operador; “Anonimização”: utilização de meios técnicos
  razoáveis e disponíveis no momento do tratamento, por meio dos quais um dado
  perde a possibilidade de associação direta ou indireta a um indivíduo;
  “Autoridade nacional”: órgão da administração pública responsável por zelar,
  implementar e fiscalizar o cumprimento desta Lei em todo o território
  nacional; “Banco de dados”: conjunto estruturado de dados pessoais,
  estabelecido em um ou em vários locais, em suporte eletrônico ou físico;
  “Bloqueio”: suspensão temporária de qualquer operação de tratamento, mediante
  guarda do dado pessoal ou do banco de dados; “Consentimento”: manifestação
  livre, informada e inequívoca pela qual o titular concorda com o tratamento de
  seus dados pessoais para uma finalidade determinada; “Controlador”: pessoa
  natural ou jurídica, de direito público ou privado, a quem competem as
  decisões referentes ao tratamento de dados pessoais; “Dado anonimizado”: dado
  relativo a titular que não possa ser identificado, considerando a utilização
  de meios técnicos razoáveis e disponíveis na ocasião de seu tratamento; “Dado
  pessoal”: informação relacionada a pessoa natural identificada ou
  identificável; “Dado pessoal sensível”: dado pessoal sobre origem racial ou
  étnica, convicção religiosa, opinião política, filiação a sindicato ou a
  organização de caráter religioso, filosófico ou político, dado referente à
  saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma
  pessoa natural; “Eliminação”: exclusão de dado ou de conjunto de dados
  armazenados em banco de dados, independentemente do procedimento empregado;
  “Encarregado”: pessoa indicada pelo controlador e operador para atuar como
  canal de comunicação entre o controlador, os titulares dos dados e a
  Autoridade Nacional de Proteção de Dados (ANPD); “Operador”: pessoa natural ou
  jurídica, de direito público ou privado, que realiza o tratamento de dados
  pessoais em nome do controlador; “Órgão de pesquisa”: órgão ou entidade da
  administração pública direta ou indireta ou pessoa jurídica de direito privado
  sem fins lucrativos, legalmente constituída sob as leis brasileiras, com sede
  e foro no Brasil, que inclua em sua missão institucional ou em seu objetivo
  social ou estatutário a pesquisa básica ou aplicada, de caráter histórico,
  científico, tecnológico ou estatístico; “Relatório de impacto à proteção de
  dados pessoais”: documentação do controlador que contém a descrição dos
  processos de tratamento de dados pessoais que podem gerar riscos às liberdades
  civis e aos direitos fundamentais, bem como medidas, salvaguardas e mecanismos
  de mitigação de risco; “Titular”: pessoa natural a quem se referem os dados
  pessoais que são objeto de tratamento; “Transferência internacional de dados”:
  transferência de dados pessoais para país estrangeiro ou organismo
  internacional do qual o país seja membro; “Tratamento”: toda operação
  realizada com dados pessoais, como as que se referem a coleta, produção,
  recepção, classificação, utilização, acesso, reprodução, transmissão,
  distribuição, processamento, arquivamento, armazenamento, eliminação,
  avaliação ou controle da informação, modificação, comunicação, transferência,
  difusão ou extração; e “Uso compartilhado de dados”: comunicação, difusão,
  transferência internacional, interconexão de dados pessoais ou tratamento
  compartilhado de bancos de dados pessoais por órgãos e entidades públicos no
  cumprimento de suas competências legais ou entre esses e entes privados,
  reciprocamente, com autorização específica para uma ou mais modalidades de
  tratamento permitidas por esses entes públicos, ou entre entes privados.
</p>

<p class="title">3. DEFINIÇÕES DA PLATAFORMA MZIC</p>
<p class="context">
  Os termos a seguir definidos podem ser utilizados no singular ou no plural,
  sem que isto afete a sua definição: “AML” significa Combate à Lavagem de
  Dinheiro. No contexto da Plataforma MZIC, refere-se ao conjunto de
  procedimentos, leis ou regulamentos destinados a impedir a prática de gerar
  renda por meio de ações ilegais e fazer com que esses recursos obtidos
  ilicitamente pareçam legais. “Artista” é o detentor original dos direitos
  patrimoniais de autor e/ou conexos aos de autor de um Conteúdo. “Blockchain”
  significa um livro-razão digital descentralizado e distribuído que registra
  transações em muitos computadores de tal forma que as transações registradas
  não podem ser alteradas retroativamente. Isso garante que todas as transações
  sejam transparentes, seguras e registradas permanentemente. “Conta Digital
  Inteligente” é uma conta na Blockchain Polygon, representada por um contrato
  inteligente. Esta conta está vinculada ao e-mail e senha do usuário na MZIC, e
  os tokens dos usuários são geridos de maneira eficiente e segura pelo contrato
  inteligente vinculado, enquanto a MZIC atua como um facilitador, assegurando a
  execução das transações dentro de seu marketplace. “Conteúdo” significa
  qualquer tipo de Obra Musical, Fonograma ou Obra Audiovisual sujeito à
  proteção pela Lei de Direitos Autorais que seja objeto de transação na
  plataforma MZIC. “Conteúdo do Usuário” significa todos os materiais e dados
  que a Plataforma MZIC permita que você carregue, envie, armazene, envie ou
  receba através da Plataforma MZIC. Você detém a propriedade de quaisquer
  direitos de propriedade intelectual que detenha sobre esse Conteúdo do
  Usuário. “Contratos Inteligentes” significam contratos autoexecutáveis com os
  termos do contrato diretamente escritos em linhas de código. Eles executam
  transações automaticamente e armazenam informações quando condições
  específicas são atendidas. “Criptomoeda” significa uma forma digital ou
  virtual de moeda que usa criptografia para segurança. Ele opera de forma
  independente de um banco central e é principalmente baseado na tecnologia
  Blockchain. “Dapp” significa Aplicativo Descentralizado. Ao contrário dos
  aplicativos tradicionais que são executados em um servidor centralizado, os
  Dapps são executados em uma rede de Blockchains que não é controlada por
  nenhuma autoridade. “Detentor de Direitos” são as pessoas físicas ou jurídicas
  que detêm certos direitos sobre os Conteúdos. Isso inclui os Artistas como
  detentores originais e os Usuários que adquirem Tokens de Fração como
  detentores por transferência de direitos. Quando os Tokens de Frações de um
  Conteúdo são transacionados na plataforma MZIC, os Usuários adquirentes também
  se tornam Detentores de Direitos sobre a parte dos direitos patrimoniais de
  autor ou conexos aos de autor representados por suas frações adquiridas pela
  transferência de direitos. “DSP” significa Digital Service Provider, que são
  as plataformas digitais de terceiros que disponibilizam o Conteúdo para
  exploração comercial dos consumidores finais, tais como Spotify, Deezer, Apple
  Music, Amazon, YouTube, entre outras. “Endereço da conta digital inteligente”
  é um identificador único associado à Conta Digital Inteligente, representando
  um destino para pagamentos e transações na Blockchain. “ERC-721” significa um
  padrão para representar a propriedade de tokens não fungíveis ou itens
  exclusivos em blockchains compatíveis com a Máquina Virtual Ethereum (EVM).
  “Fonograma” significa toda fixação de sons de uma execução ou interpretação ou
  de outros sons, ou de uma representação de sons que não seja uma fixação
  incluída em uma Obra Audiovisual. “KYC” significa Know You Customer (Conheça
  seu cliente). Na MZIC, refere-se ao processo de identificação e verificação da
  identidade de seus Usuários. “Lei de Direitos Autorais” e “LDA” significam a
  Lei Federal Brasileira 9.610, de 19 de fevereiro de 1998. “Moeda Fiduciária”
  significa uma moeda emitida pelo governo que não é lastreada por um bem
  físico, como ouro ou prata, mas pela confiança das pessoas. “Minting”
  significa o processo de criação de um novo ativo digital, como um token ou
  moeda, em uma Blockchain. “Obra Musical” significa uma composição musical,
  tenha letra ou não. “Obra Audiovisual” significa a obra que resulta da fixação
  de imagens com ou sem som, que tenha a finalidade de criar por meio de sua
  reprodução a impressão de movimento, independentemente dos processos de sua
  captação, do suporte usado inicial ou posteriormente para fixá-lo, bem como
  dos meios utilizados para sua veiculação. “Plataforma MZIC” significa o
  conjunto de aplicativos proprietários da MZIC LLC, vinculados ou não a
  aplicativos de terceiros, que são disponibilizados aos Usuários. “Polygon”
  significa uma plataforma de código aberto baseada em Blockchain que suporta
  Contratos Inteligentes. Ele fornece a infraestrutura para a criação de
  aplicativos e tokens descentralizados. “Procuração” refere-se à autoridade que
  a Plataforma MZIC detém em nome dos Detentores de Direitos. A Plataforma MZIC
  usa essa autoridade para arrecadar e distribuir Royalties, proteger contra
  violações de direitos e remover Conteúdos de plataformas não autorizadas.
  “Royalties” significam os pagamentos feitos aos coproprietários de um Conteúdo
  pela sua utilização comercial. Os Royalties são arrecadados e distribuídos
  para os proprietários de Tokens de Fração. Ao adquirir Tokens de Fração, os
  Usuários podem vir a ser Detentores de Direitos e podem vir a ter o direito de
  receber Royalties. “Token” significa um ativo digital criado em um Blockchain.
  Ele pode representar uma variedade de ativos digitais e físicos e pode ser
  usado para uma infinidade de propósitos, inclusive como uma unidade de valor,
  uma representação de propriedade ou direitos de acesso. “Tokens de Fração”
  referem-se aos Tokens ERC-721 que representam uma participação na propriedade
  de um Conteúdo. Ao adquirir Tokens de Fração, o Usuário se torna proprietário
  de uma fração proporcional dos direitos patrimoniais de autor ou conexos aos
  de autor relacionados a esse Conteúdo. Isso pode incluir o direito a uma parte
  dos Royalties gerados pela utilização comercial do Conteúdo proporcionais à
  fração dos direitos adquiridos. “Tokens de Música” referem-se aos Tokens
  ERC-721 utilizados para representar de forma exclusiva os direitos
  patrimoniais de autor ou conexos aos de autor de um Conteúdo original de um
  Artista. Estes tokens não possuem valor patrimonial intrínseco, mas servem
  como uma representação digital dos direitos de autor ou conexos de um Conteúdo
  original de um Artista. “Usuário” significa qualquer pessoa física ou jurídica
  que se registre para uma conta na Plataforma MZIC.
</p>

<p class="title">4. RESPONSÁVEIS PELO TRATAMENTO DE DADOS</p>
<p class="context">
  Para os serviços oferecidos pela Plataforma MZIC, todas as decisões sobre o
  tratamento dos dados pessoais de seus Usuários são tomadas pela Empresa, assim
  como o efetivo tratamento dos dados propriamente ditos.
</p>

<p class="title">5. FONTES DE COLETA DE DADOS</p>
<p class="context">
  A Plataforma MZIC coleta os seguintes dados de seus usuários através das
  seguinte fontes:
</p>
<p class="context">
  - Páginas Eletrônicas da Plataforma MZIC: Todos os sites e portais oficiais da
  Plataforma MZIC podem ser utilizados para coleta de dados pessoais. Isso
  inclui tanto o site que a Empresa opera diretamente através dos domínios e
  endereços IPs da Empresa quanto sites ou páginas que estabelecidas pela
  Empresa em serviços de terceiros como Facebook, Instagram, LinkedIn e demais
  terceiros que ofereçam esse tipo de serviço;
</p>
<p class="context">
  - Correio Eletrônico e sistemas de troca de mensagens instantâneas: Serviços
  utilizados para manter comunicações eletrônicas entre o Usuário e a Plataforma
  MZIC, incluindo serviços disponibilizados diretamente pela Empresa ou serviços
  de terceiros como WhatsApp, SMS (serviço de mensagens curtas) e similares;
</p>
<p class="context">
  - Aplicativos móveis da Plataforma MZIC: Aplicativos móveis fornecidos
  diretamente pela Empresa ou através de serviços de terceiros como Google,
  Apple e outros;
</p>
<p class="context">
  - Central de Atendimento Contact Center e Áreas Comerciais: Comunicações
  realizadas com o Usuário ou pelo Usuário através das centrais da Empresa de
  atendimento e relacionamento com o Usuário;
</p>
<p class="context">
  - Anúncios, propagandas e formulários online: Interações com qualquer tipo de
  anúncios, propagandas e formulários online da Plataforma MZIC ou de parceiros
  estratégicos;
</p>
<p class="context">
  - Registros offline: Registros preenchidos offline, distribuídos durante
  eventos e outras interações com a Plataforma MZIC e seus parceiros;
</p>
<p class="context">
  - Dados recebidos de terceiros: Incluindo redes sociais e sites de terceiros
  como, por exemplo, Facebook, Instagram, LinkedIn e similares, serviços de
  agregadores de dados, parceiros da Plataforma MZIC e fontes públicas.
</p>

<p class="title">6. DADOS COLETADOS, TRATADOS E BASES LEGAIS</p>
<p class="context">
  A Empresa pode coletar os seguintes dados de seus Usuários durante a sua
  interação com a Plataforma MZIC através das fontes de coleta de dados já
  mencionadas acima:
</p>

<p class="context">
  (1) Dados coletados quando o Usuário cria uma conta na Plataforma MZIC
</p>
<p class="context">
  - Dados do Usuário: Quando um Usuário se registra em uma conta na Plataforma
  MZIC, a Empresa coleta dados básicos de identificação e informações de
  contato. O tipo de dado coletado e tratado depende do tipo de serviços que o
  Usuário tem acesso na Plataforma MZIC. Depende também do tipo de conta criada
  pelo Usuário, do país no qual o Usuário se localiza e se o Usuário utiliza de
  serviços de terceiros para acessar a sua conta. Tudo isso pode incluir os
  seguintes dados e informações do Usuário:
</p>
<p class="context">- Nome civil;</p>
<p class="context">- Endereço de correio eletrônico (email);</p>
<p class="context">- Número de telefone;</p>
<p class="context">- Senha;</p>
<p class="context">- Nome do perfil do Usuário.</p>

<p class="context">
  - Dados de Endereço Físico do Usuário: A Empresa pode vir a solicitar e tratar
  os dados do endereço físico do Usuário pelas seguintes razões:
</p>
<p class="context">
  a) Para checar a elegibilidade do Usuário para uma opção de serviço da
  Plataforma MZIC;
</p>
<p class="context">b) Para enviar comunicados exigidos por lei;</p>
<p class="context">
  c) Para enviar opções de assistência e suporte ao Usuário;
</p>
<p class="context">d) Para cobranças financeiras.</p>

<p class="context">
  Em alguns casos, a Empresa pode vir a usar aplicativos de terceiros, como o
  Places API da Google, para auxiliar o Usuário na verificação de seu endereço.
</p>

<p class="context">
  Bases legais para a coleta e tratamento de dados: Cumprimento de obrigações
  legais ou regulatórias, cumprimento de obrigações contratuais e legítimo
  interesse do Controlador ou de terceiros.
</p>

<p class="context">
  (2) Dados coletados quando o Usuário utiliza os serviços da Plataforma MZIC
</p>
<p class="context">
  Esta informação está relacionada aos dados e informações pessoais coletados e
  tratados quando o Usuário acessa e/ou utiliza os serviços da Plataforma MZIC,
  listados nas seguintes seções de forma não taxativa, formando a categoria de
  Dados de Uso.
</p>
<p class="context">
  a) Informação sobre como o Usuário utiliza os serviços da Plataforma MZIC:
</p>
<p class="context">
  - Informação sobre as opções do Usuário pelos serviços da Plataforma MZIC;
</p>
<p class="context">
  - Informações sobre a interação do Usuário com os serviços da Plataforma MZIC,
  incluindo data e hora, tais como:
</p>
<p class="context">i) Tokens de Fração que o Usuário adquire ou dispõe;</p>
<p class="context">ii) Resultados de pesquisas;</p>
<p class="context">iii) Histórico de streaming;</p>
<p class="context">iv) Preferências e configurações;</p>
<p class="context">
  v) Interações com outros Usuários dos serviços da Plataforma MZIC;
</p>
<p class="context">vi) Histórico de navegação;</p>
<p class="context">
  - Entendimento da Empresa dos interesses e preferências do Usuário baseadas na
  utilização dos serviços da Plataforma MZIC;
</p>
<p class="context">- Conteúdo do Usuário.</p>
<p class="context">b) Dados técnicos:</p>
<p class="context">- Informação de URL;</p>
<p class="context">
  - Identificadores online, como dados de cookies e endereço de IP;
</p>
<p class="context">
  - Informações sobre os dispositivos utilizados pelo Usuário, tais como:
</p>
<p class="context">i) ID do dispositivo;</p>
<p class="context">
  ii) Tipo de conexão de rede (Wifi, 4G, 5G, LTE, Bluetooth, etc);
</p>
<p class="context">iii) Provedor de acesso;</p>
<p class="context">iv) Performance da rede e do dispositivo;</p>
<p class="context">v) Tipo de navegador;</p>
<p class="context">vi) Língua;</p>
<p class="context">vii) Sistema operacional;</p>
<p class="context">viii) Versão do aplicativo MZIC.</p>
<p class="context">
  c) Política de Cookies: Cookies são arquivos salvados no telefone, tablet ou
  computador do Usuário ao visitar um website. Para informações sobre como a
  Empresa usa cookies e como o Usuário pode gerenciar sua política de cookies,
  veja a política de Cookies no item 14 abaixo.
</p>
<p class="context">
  d) Localização Geral (não-precisa): A localização geral do Usuário inclui o
  país, a região ou o estado. A Empresa pode ter acesso a esta informação
  através dos dados técnicos, como o endereço de IP do Usuário ou da
  configuração de linguagem do seu dispositivo. Isto ajuda a Empresa a:
</p>
<p class="context">
  i) Atender a requisitos geográficos com os contratos da Empresa com detentores
  de direitos autorais; e
</p>
<p class="context">
  ii) Entregar conteúdo e serviços que sejam relevantes para o Usuário.
</p>
<p class="context">
  e) Dados de transações: Isto inclui detalhes sobre os Tokens de Fração que o
  Usuário adquire ou dispõe, bem como dos Royalties que o Usuário recebe. Estes
  dados permitem que a Empresa gerencie transações, calcule Royalties e atenda
  ao Usuário com serviços relevantes.
</p>
<p class="context">
  f) Dados do sensor do dispositivo: São dados do sensor do dispositivo do
  Usuário, gerados por movimento ou por orientação, eventualmente necessários
  para prover funcionalidades do serviço do Spotify que requeiram tais dados.
  Estes dados são coletados pelos dispositivos do Usuário pela forma com que o
  Usuário os segura ou os movimenta.
</p>

<p class="context">
  Bases legais para a coleta e tratamento de dados: Cumprimento de obrigações
  legais ou regulatórias, cumprimento de obrigações contratuais e legítimo
  interesse do Controlador ou de terceiros.
</p>

<p class="context">(3) Dados adicionais que o Usuário pode optar em fornecer</p>
<p class="context">
  a) Dados dos métodos de pagamento: Isto inclui os dados pessoais que o Usuário
  fornece ao adicionar um método de pagamento em sua conta na Plataforma MZIC,
  tais como:
</p>
<p class="context">i) Nome civil;</p>
<p class="context">
  ii) Espécie de método de pagamento (cartão de crédito, cartão de débito, etc);
</p>
<p class="context">
  iii) Se o Usuário adiciona um cartão de crédito ou débito, o tipo ou bandeira
  do cartão, número do cartão, data de expiração e código de segurança; e
</p>
<p class="context">
  iv) Endereço de cobrança associado ao cartão (CEP, endereço completo do
  Usuário, cidade, estado, país, etc).
</p>
<p class="context">
  Nota: Por segurança, a Empresa nunca guarda as informações completas dos
  métodos de pagamento, mas somente o necessário para processar os pagamentos.
</p>
<p class="context">
  b) Dados das transações: Isto inclui os dados pessoais do Usuário tratados
  quando o Usuário inicia uma aquisição de Tokens de Fração na Plataforma MZIC.
  Estas informações permitem o processamento eficiente das transações efetuadas
  na Plataforma MZIC, tais como:
</p>
<p class="context">i) Detalhes associados aos métodos de pagamento;</p>
<p class="context">ii) Data e hora da transação; e</p>
<p class="context">iii) Detalhes do montante da transação.</p>
<p class="context">
  c) Dados de verificação de identidade: Isto inclui os dados pessoais do
  Usuário tratados quando o Usuário inicia uma disposição de Tokens de Fração ou
  a arrecadação de Royalties na Plataforma MZIC. Estas informações são
  necessárias para atender à legislação AML e proteger outros Usuários de
  atividades fraudulentas. Os dados pessoais do Usuário coletados e tratados
  incluem:
</p>
<p class="context">
  i) CPF (ou similar, a depender do país de localidade do Usuário);
</p>
<p class="context">ii) Data de nascimento;</p>
<p class="context">iii) Endereço completo;</p>
<p class="context">
  iv) Documentos de identidade (carteira de identidade, carteira de motorista,
  passaporte, etc).
</p>
<p class="context">
  Especificamente no caso dos Detentores de Direitos, como o processo de
  verificação KYC é obrigatório para o acesso a determinados serviços da
  Plataforma MZIC, serão coletadas e tratadas adicionalmente as seguintes
  informações:
</p>
<p class="context">i) País de localidade;</p>
<p class="context">ii) Número do telefone celular;</p>
<p class="context">iii) Foto do documento de identidade; e</p>
<p class="context">iv) Foto do próprio Usuário (selfie).</p>
<p class="context">
  Nota: Todos os dados mencionados nesta seção (c) são coletados pelo serviço
  terceirizado da Empresa chamado Plaid, especializado em serviços financeiros
  seguros. Para mais detalhes de como o Plaid coleta e trata os dados pessoais
  dos Usuários, por favor, leia a política de privacidade do Plaid em
  https://plaid.com/legal/#end-user-privacy-policy.
</p>
<p class="context">
  d) Dados de pesquisa: Isto inclui os dados que o Usuário fornece à Empresa ao
  participar de uma pesquisa e que são coletados e tratados pela Empresa.
</p>

<p class="context">
  Bases legais para a coleta e tratamento de dados: Cumprimento de obrigações
  legais ou regulatórias, cumprimento de obrigações contratuais e legítimo
  interesse do Controlador ou de terceiros, estudos por órgão de pesquisa.
</p>

<p class="title">7. TRATAMENTO PARA OUTRAS FINALIDADES</p>
<p class="context">
  No cenário digital atual, os dados desempenham um papel crucial na melhoria da
  experiência do Usuário, na segurança aprimorada e no cumprimento das operações
  legais. A Plataforma MZIC coleta e processa dados pessoais para personalizar
  suas interações, manter uma plataforma segura, cumprir obrigações legais,
  pesquisar e desenvolver melhores recursos e fornecer conteúdo de marketing e
  publicidade relevante. Alinhado com nosso compromisso com a transparência,
  esta seção tem como objetivo fornecer informações claras e abrangentes sobre
  nossas finalidades para o uso dos dados pessoais dos Usuários e as atividades
  específicas de processamento de dados relacionadas a cada finalidade.
</p>

<p class="context">
  a) Prover, Personalizar e Melhorar os Serviços da Plataforma MZIC
</p>
<p class="context">
  Plataforma MZIC utiliza os dados pessoais do Usuário para aprimorar a
  prestação de serviços. Isso inclui configurar a conta, personalizar a
  experiência, fornecer recomendações personalizadas e melhorar os recursos e a
  experiência do Usuário da Plataforma MZIC. O objetivo é otimizar as interações
  e atender às necessidades individuais de cada Usuário. Propósito para o
  tratamento: Prover, Personalizar e Melhorar os Serviços da Plataforma MZIC.
  Base legal: Cumprimento de obrigações contratuais e legítimo interesse do
  Controlador ou de terceiros. Categorias de dados tratados: Dados do Usuário,
  Dados de Uso, Dados dos métodos de pagamento, Dados de endereço físico do
  Usuário.
</p>

<p class="context">b) Habilitar o Compartilhamento Social</p>
<p class="context">
  A Plataforma MZIC utiliza os dados pessoais dos Usuários para habilitar
  recursos que permitem que eles compartilhem conteúdo com outras pessoas. Isso
  melhora a experiência do Usuário ao promover conexões sociais e permite que o
  Usuário se envolva mais profundamente com o conteúdo. Propósito para o
  tratamento: Habilitar o Compartilhamento Social. Base legal: Cumprimento de
  obrigações contratuais e legítimo interesse do Controlador ou de terceiros.
  Categorias de dados tratados: Dados do Usuário, Dados de Uso.
</p>

<p class="context">c) Processamento de Transações</p>
<p class="context">
  Os dados pessoais do Usuário são essenciais para o processamento de transações
  na Plataforma MZIC. Isso inclui verificar os detalhes de pagamento do Usuário,
  executar transações financeiras relacionadas à aquisição de Tokens de Fração e
  manter um registro de todas as transações do Usuário para referência futura e
  serviços de suporte ao cliente. Propósito para o tratamento: Processamento de
  Transações. Base legal: Cumprimento de obrigações contratuais e legítimo
  interesse do Controlador ou de terceiros. Categorias de dados tratados: Dados
  do Usuário, Dados de transações, Dados dos métodos de pagamento, Dados de
  endereço físico do Usuário.
</p>

<p class="context">d) Segurança e Proteção</p>
<p class="context">
  A Plataforma MZIC trata os dados pessoais do Usuário para garantir a segurança
  da plataforma. Isso inclui medidas como verificar a identidade do Usuário,
  detectar e prevenir fraudes, garantir a segurança das transações e proteger os
  interesses dos Usuários. Propósito para o tratamento: Segurança e Proteção.
  Base legal: Cumprimento de obrigações legais ou regulatórias, cumprimento de
  obrigações contratuais e legítimo interesse do Controlador ou de terceiros.
  Categorias de dados tratados: Dados do Usuário, Dados de Uso, Dados de
  transações, Dados dos métodos de pagamento, Dados de endereço físico do
  Usuário, Dados de verificação de identidade.
</p>

<p class="context">e) Obrigações Legais e Contratuais com Terceiros</p>
<p class="context">
  A Plataforma MZIC trata os dados pessoais do Usuário para cumprir obrigações
  legais e contratuais. Isso inclui responsabilidades para com terceiros, como
  Detentores de Direitos e atividades relacionadas à execução e aplicação de
  contratos de propriedade de Direitos Autorais. Essas atividades podem envolver
  o tratamento de transações, a gestão de pagamentos de Royalties e o
  compartilhamento de dados relevantes conforme obrigações contratuais ou
  exigido por lei. Propósito para o tratamento: Obrigações Legais e Contratuais
  com Terceiros. Base legal: Cumprimento de obrigações legais ou regulatórias,
  cumprimento de obrigações contratuais e legítimo interesse do Controlador ou
  de terceiros. Categorias de dados tratados: Dados do Usuário, Dados de
  transações, Dados dos métodos de pagamento, Dados de endereço físico do
  Usuário, Dados de verificação de identidade.
</p>

<p class="context">f) Ação contra Violação de Direitos Autorais</p>
<p class="context">
  A Plataforma MZIC trata os dados pessoais do Usuário para detectar e prevenir
  violações de Direitos Autorais. Isso inclui identificar e tomar medidas
  adequadas contra o uso não autorizado ou compartilhamento de Conteúdo
  protegido por Direitos Autorais. Propósito para o tratamento: Ação contra
  Violação de Direitos Autorais. Base legal: Cumprimento de obrigações legais ou
  regulatórias, cumprimento de obrigações contratuais e legítimo interesse do
  Controlador ou de terceiros. Categorias de dados tratados: Dados do Usuário,
  Dados de Uso.
</p>

<p class="context">g) Reivindicações Legais</p>
<p class="context">
  Os dados pessoais do Usuário podem ser tratados para estabelecer, exercer ou
  defender reivindicações legais. Isso é essencial para proteger os direitos da
  Empresa, sua propriedade ou segurança, bem como os direitos, propriedade ou
  segurança dos Usuários ou de terceiros. Propósito para o tratamento:
  Reivindicações Legais. Base legal: Cumprimento de obrigações legais ou
  regulatórias, cumprimento de obrigações contratuais e legítimo interesse do
  Controlador ou de terceiros. Categorias de dados tratados: Dados do Usuário,
  Dados de Uso, Dados de transações, Dados de endereço físico do Usuário, Dados
  de verificação de identidade.
</p>

<p class="context">
  h) Resolução de Problemas e Melhora do Serviço da Plataforma MZIC
</p>
<p class="context">
  Os dados pessoais dos Usuários são essenciais para diagnosticar, solucionar e
  corrigir quaisquer problemas relacionados aos serviços da Plataforma MZIC.
  Isso permite manter o desempenho, funcionalidade e confiabilidade da
  Plataforma MZIC. Propósito para o tratamento: Resolução de Problemas e Melhora
  do Serviço da Plataforma MZIC. Base legal: Legítimo interesse do Controlador
  ou de terceiros. Categorias de dados tratados: Dados do Usuário, Dados de Uso,
  Dados de transações.
</p>

<p class="context">i) Pesquisa e Desenvolvimento</p>
<p class="context">
  A Plataforma MZIC trata os dados pessoais do Usuário para informar iniciativas
  de pesquisa e desenvolvimento da Plataforma MZIC. Isso permite compreender o
  comportamento do Usuário, melhorar os serviços da Plataforma MZIC e inovar
  novas funcionalidades para aprimorar a experiência do Usuário. Propósito para
  o tratamento: Pesquisa e Desenvolvimento. Base legal: Legítimo interesse do
  Controlador ou de terceiros. Categorias de dados tratados: Dados de Uso, Dados
  de transações.
</p>

<p class="context">j) Marketing e Publicidade</p>
<p class="context">
  Os dados pessoais do Usuário ajudam a desenvolver, entregar e rastrear a
  eficácia de campanhas de marketing e publicidade relacionadas à Plataforma
  MZIC. Isso inclui personalizar o conteúdo para corresponder às preferências e
  interesses do Usuário. Propósito para o tratamento: Marketing e Publicidade.
  Base legal: Legítimo interesse do Controlador ou de terceiros. Categorias de
  dados tratados: Dados do Usuário, Dados de Uso, Dados de transações.
</p>

<p class="title">8. DIREITOS DOS USUÁRIOS</p>
<p class="context">
  São direitos do titular de dados pessoais nos termos da LGPD: - Direito de
  confirmação e acesso: direito do titular de dados de obter do serviço a
  confirmação de que os dados pessoais que lhe digam respeito são ou não objeto
  de tratamento e, se for esse o caso, o direito de acessar os seus dados
  pessoais; - Direito de retificação: direito de solicitar a correção de dados
  incompletos, inexatos ou desatualizados; - Direito à limitação do tratamento
  dos dados: direito do titular de dados de limitar o tratamento de seus dados
  pessoais, podendo exigir a eliminação de dados desnecessários, excessivos ou
  tratados em desconformidade com o disposto na LGPD; - Direito de oposição:
  direito do titular de dados de, a qualquer momento, opor-se ao tratamento de
  dados por motivos relacionados com a sua situação particular, com fundamento
  em uma das hipóteses de dispensa de consentimento ou em caso de descumprimento
  ao disposto na LGPD; - Direito de portabilidade dos dados: direito do titular
  de dados de realizar a portabilidade dos dados a outro fornecedor de serviço
  ou produto, mediante requisição expressa, de acordo com a regulamentação da
  autoridade nacional, observados os segredos comercial e industrial; e -
  Direito de não ser submetido a decisões automatizadas: direito do titular de
  dados de solicitar a revisão de decisões tomadas unicamente com base em
  tratamento automatizado de dados pessoais que afetem seus interesses,
  incluídas as decisões destinadas a definir o seu perfil pessoal, profissional,
  de consumo e de crédito ou os aspectos de sua personalidade.
</p>

<p class="title">9. COMPARTILHAMENTO DE DADOS</p>
<p class="context">
  A Empresa informa que irá compartilhar os dados dos usuários coletados na
  Plataforma MZIC com terceiros. Para manter a transparência entre os usuários e
  a Empresa, o compartilhamento de dados observará integralmente o disposto nas
  Políticas de Privacidade da Plataforma MZIC e será feito primariamente com
  empresas filiadas à Empresa ou de seu grupo econômico. Também poderão ser
  compartilhados os dados dos usuários das seguintes maneiras:
</p>

<p class="context">Informação Publicamente Disponível</p>
<p class="context">
  Um aspecto crucial da operação da Plataforma MZIC é a disponibilização para
  visibilidade pública de certos dados pessoais, o que é essencial para a
  criação de um senso de comunidade e aprimoramento da experiência do Usuário.
  Esta transparência permite que o Usuário compreenda a dinâmica da Plataforma
  MZIC, faça decisões ponderadas e engaje de forma integral com o que a
  Plataforma MZIC oferece. No espírito de total transparência, os seguintes
  dados pessoais do Usuário estarão sempre disponíveis para visibilidade pública
  dentro da Plataforma MZIC: - Nome do perfil; - Foto do perfil; - Foto de fundo
  do perfil; - Endereço de Carteira Digital Inteligente; - Inventário atual de
  Tokens de Fração; - Detalhes como o endereço do Contrato Inteligente na
  Blockchain e outras informações relacionadas à Blockchain. O Usuário pode
  compartilhar determinadas informações em serviços de terceiros, como redes
  sociais ou plataformas de mensagens instantâneas. Isto inclui os seguintes
  dados do Usuário: - Nome do perfil; - URL do perfil; - Conteúdo do Usuário e
  detalhes sobre ele; - Endereço de Carteira Digital Inteligente. Quando este
  compartilhamento é feito, os serviços de terceiros podem eventualmente manter
  uma cópia destas informações para permitir funcionalidades. Uma vez que os
  dados pessoais são compartilhados ou mantidos por um serviço de terceiros, o
  controle desses dados é governado pelas políticas de privacidade de cada
  serviço de terceiro.
</p>

<p class="context">Serviços de Terceiros</p>
<p class="context">
  A Plataforma MZIC, algumas vezes, compartilha os dados do Usuário com serviços
  de terceiros para que estes possam ajudar a Plataforma MZIC a prover,
  melhorar, proteger e promover a Plataforma MZIC. Por exemplo, a Plataforma
  MZIC compartilha dados dos métodos de pagamento e dados do endereço físico do
  Usuário com nosso provedor de processamento de pagamentos para cuidar de
  transações financeiras. Esses serviços de terceiros estão ligados a regras e
  leis de suas respectivas localidades e possuem rígidas políticas de tratamento
  de dados para garantir a segurança e privacidade de dados dos Usuários.
  Serviço de terceiro: Processamento de pagamento. Categorias de dados
  compartilhados: Dados do Usuário, Dados dos métodos de pagamentos, Dados de
  transações, Dados de endereço físico do Usuário, Dados de verificação de
  identidade. Propósito do compartilhamento: Processamento de pagamentos do
  Usuário.
</p>

<p class="context">Transferências Corporativas</p>
<p class="context">
  Caso ocorra uma reestruturação corporativa, fusão, aquisição ou venda da
  Plataforma MZIC, os dados pessoais do Usuário coletados pela Plataforma MZIC
  podem estar entre os ativos transferidos. Uma vez que a Plataforma MZIC não
  guarda dados pessoais sensíveis, esses dados não serão transferidos nessas
  ocorrências.
</p>

<p class="title">10. TEMPO DE ARMAZENAMENTO DOS DADOS PESSOAIS DO USUÁRIO</p>
<p class="context">
  A Empresa acredita em reter os dados pessoais do Usuário apenas pelo tempo
  necessário para cumprir os propósitos para os quais foram coletados, inclusive
  para satisfazer quaisquer requisitos legais, contábeis ou de relatórios. Os
  dados pessoais do Usuário são mantidos apenas pelo tempo necessário para
  fornecer os serviços da Plataforma MZIC e para os legítimos e essenciais
  propósitos comerciais da Plataforma MZIC, como: - Manutenção do desempenho dos
  serviços da Plataforma MZIC; - Tomada de decisões comerciais baseadas em
  dados; - Cumprimento de obrigações legais; - Resolução de disputas de
  terceiros.
</p>
<p class="context">a) Retenção de Dados</p>
<p class="context">
  Os dados pessoais do Usuário são uma questão de confiança e respeito para a
  Empresa e eles são retidos apenas pelo tempo necessário, permanecendo seguros
  até que o Usuário decida encerrar sua conta na Plataforma MZIC. No momento em
  que o Usuário opta por fazer isso, a Empresa trata a decisão do Usuário com
  grande responsabilidade. A Empresa descarta imediatamente certas informações,
  como os Dados dos métodos de pagamento, que não são mais necessários,
  garantindo que os dados pessoais do Usuário saiam dos sistemas da Plataforma
  MZIC quando não servirem mais a nenhum propósito para os serviços da
  Plataforma MZIC. Para contas inativas, a Plataforma MZIC adota uma abordagem
  cuidadosa em vez de um processo de exclusão automatizado. Isso ocorre porque,
  pela natureza dos serviços da Plataforma MZIC, essas contas podem possuir
  ativos ou saldos financeiros com a Plataforma MZIC. Preservar os dados
  associados a essas contas permite proteger os interesses do Usuário e garantir
  que seus ativos ou saldos financeiros sejam gerenciados adequadamente.
</p>
<p class="context">b) Após o Encerramento da Conta na Plataforma MZIC</p>
<p class="context">
  Mesmo após o encerramento da conta, a Plataforma MZIC continua armazenando
  alguns dos dados do Usuário para se defender contra possíveis investigações
  legais ou cumprir requisitos regulatórios, como regulamentos de Anti-Lavagem
  de Dinheiro (AML), cobrança de tributos e outros. Os dados são mantidos
  estritamente para esses fins legais e não são usados para operações regulares.
</p>
<p class="context">c) Necessidades Legais e Comerciais</p>
<p class="context">
  A Empresa pode reter os dados pessoais do Usuário por um período mais longo em
  caso de reclamação ou se a Empresa acredita razoavelmente que há uma
  perspectiva de litígio em relação ao relacionamento da Empresa com o Usuário.
  Por exemplo, podemos manter dados relevantes para investigações ou disputas em
  andamento pelo período necessário até a resolução.
</p>
<p class="context">d) Anonimização e Agregação de Dados</p>
<p class="context">
  Podemos anonimizar e/ou agregar dados pessoais e usar esses dados anonimizados
  ou agregados para nossas estratégias internas de negócios e marketing. Esse
  uso não o identifica individualmente e nos ajuda a melhorar nossos serviços e
  experiências do usuário.
</p>
<p class="context">e) Controle do Usuário sobre os Dados</p>
<p class="context">
  O Usuário tem o direito de solicitar a exclusão de seus dados em determinadas
  circunstâncias. No entanto, o Usuário deve observar que a exclusão de
  determinados dados pode afetar a capacidade de usar os serviços da Plataforma
  MZIC, pois só são coletados e tratados dados absolutamente necessários para a
  operação da Plataforma MZIC. O Usuário pode fazer essa solicitação por meio do
  centro de Configurações no aplicativo móvel da Plataforma MZIC ou enviando um
  e-mail para o departamento jurídico em
  <LINK />
  legal&#64;mzic.io.
</p>
<p class="context">f) Notificação de Violação de Dados</p>
<p class="context">
  Uma violação de dados refere-se a um incidente em que indivíduos não
  autorizados obtêm acesso à Plataforma MZIC e acessam dados pessoais. As
  violações podem resultar tanto de ações maliciosas externas, como
  ciberataques, quanto de vulnerabilidades internas do sistema ou erros humanos.
</p>
<p class="context">g) Resposta a uma Violação de Dados</p>
<p class="context">
  Ao identificar uma possível violação de dados, é iniciada uma investigação
  imediata. A equipe da Plataforma MZIC de resposta avaliará a extensão da
  violação, identificará os dados afetados e tomará as medidas necessárias para
  conter e mitigar a violação. Se for determinada que uma violação de dados pode
  ter comprometido os dados pessoais do Usuário, o Usuário será notificado o
  mais rápido possível. Esta notificação conterá informações sobre a natureza da
  violação, o tipo de dados que foram potencialmente comprometidos, as medidas
  que foram tomadas para abordar a violação e o que o Usuário pode fazer para se
  proteger ainda mais. Serão usados os detalhes de contato fornecidos pelo
  Usuário para comunicar sobre a violação de dados. No entanto, se não for
  possível contatar o Usuário por meio desses canais ou se for preciso notificar
  um grande número de Usuários de uma só vez, podem ser usados métodos de
  comunicação adicionais, como avisos públicos no site da Plataforma MZIC ou
  anúncios por meio de nossos serviços na Plataforma MZIC. Qualquer violação de
  dados também poderá ser informada às autoridades relevantes conforme exigido
  por lei, e a Empresa cooperará totalmente com elas em suas investigações. Após
  qualquer violação de dados, será realizada uma revisão completa para entender
  como a violação ocorreu e como foi feita a resposta. Essas informações serão
  usadas para melhorar as medidas de segurança e evitar incidentes semelhantes
  no futuro.
</p>
<p class="context">h) Arquivamento e Backup de Dados</p>
<p class="context">
  A Empresa arquiva dados para manter um registro de longo prazo da atividade
  dos Usuários na Plataforma MZIC. O arquivamento ajuda a Empresa a manter um
  banco de dados robusto e organizado cronologicamente que pode apoiar
  conformidade legal e regulatória, análises históricas e auditorias internas.
  Além do arquivamento, são realizados backups regulares dos bancos de dados
  relativos à Plataforma MZIC. Esses backups criam duplicatas dos dados em
  intervalos predeterminados, protegendo contra a perda de dados em caso de
  problemas técnicos, falhas de sistema ou circunstâncias imprevistas. Todos os
  arquivos e backups da Plataforma MZIC são armazenados em centros de dados
  seguros com controles rígidos de acesso. Esses centros empregam medidas de
  segurança física e virtual avançadas para proteger os dados contra acesso não
  autorizado, corrupção ou destruição.
</p>
<p class="context">i) Restauração de Dados</p>
<p class="context">
  Em caso de perda de dados, existem procedimentos para restauração rápida e
  completa de dados dos backups relativos à Plataforma MZIC. No entanto, embora
  o objetivo seja a recuperação abrangente, não é possível garantir a
  restauração de cada pedaço de dados em todas as circunstâncias. O Usuário deve
  notar que, apesar dos rigorosos procedimentos de arquivamento e backup de
  dados relativos à Plataforma MZIC, não é possível eliminar completamente o
  risco de perda de dados. A Empresa incentiva os Usuários a manter backups
  pessoais de seus dados mais importantes.
</p>

<p class="title">11. SEGURANÇA DOS DADOS PESSOAIS</p>
<p class="context">
  A Empresa está comprometida em proteger os dados pessoais dos Usuários. A
  Empresa implementa várias camadas de proteção para evitar acesso não
  autorizado e retenção desnecessária de dados pessoais nos sistemas
  relacionados à Plataforma MZIC. Estas incluem pseudonimização, criptografia,
  políticas de acesso e retenção. É importante notar que a segurança da conta
  também está nas mãos do Usuário, por isso é recomendado ao Usuário que: - Use
  uma senha única e forte exclusivamente para sua conta na Plataforma MZIC; -
  Nunca compartilhe sua senha com ninguém; - Limite o acesso ao seu computador e
  navegador; a Plataforma MZIC permite conexões de dois dispositivos dentro do
  mesmo endereço IP. - Faça logout após terminar sua sessão na Plataforma MZIC,
  especialmente em dispositivos compartilhados. - Entre em contato imediatamente
  com o suporte da Plataforma MZIC se notar qualquer atividade suspeita. Além
  disso, é disponibilizado um recurso de “Sair de todos os lugares” para
  fortalecer ainda mais a segurança da conta do Usuário na Plataforma MZIC. É
  importante que o Usuário lembre que é sua responsabilidade gerenciar o acesso
  à sua conta na Plataforma MZIC. Estas responsabilidades estão claramente
  mencionadas nos Termos de Uso da Plataforma MZIC disponíveis em
  https://mzic.io/tos. Se outras pessoas tiverem acesso à conta do Usuário na
  Plataforma MZIC, elas poderão acessar dados pessoais, controles e ativos
  disponíveis na conta. É responsabilidade do Usuário permitir que apenas
  pessoas autorizadas usem sua conta e que o Usuário se sinta confortável em
  compartilhar esses dados pessoais com elas. Além disso, só é possível tomar
  medidas para proteger a conta do Usuário na Plataforma MZIC mediante
  recebimento de uma solicitação direta do email do Usuário da conta. A Empresa
  trabalha continuamente para aprimorar as medidas de segurança e fornecer
  sempre uma Plataforma MZIC segura e protegida.
</p>

<p class="title">12. DADOS DE CRIANÇAS E ADOLESCENTES</p>
<p class="context">a) Limite de Idade Mínima</p>
<p class="context">
  Os serviços da Plataforma MZIC não são projetados ou destinados a indivíduos
  com menos de 18 anos. Este limite de idade está alinhado com a definição de
  maioridade de muitas legislações e reflete o compromisso da Empresa em
  proteger os menores de idade de potenciais riscos.
</p>
<p class="context">b) Prevenção de Coleta</p>
<p class="context">
  Não coletamos nem processamos conscientemente dados pessoais de indivíduos com
  menos de 18 anos. Se o Usuário tem menos de 18 anos, por favor, não utilize a
  Plataforma MZIC e evite fornecer quaisquer dados pessoais.
</p>
<p class="context">c) Conscientização e Responsabilidade dos Pais</p>
<p class="context">
  Se o Usuário é pai ou responsável e fica sabendo que seu filho que tem menos
  de 18 anos forneceu dados pessoais à Plataforma MZIC, o Usuário deve entrar em
  contato com a Plataforma MZIC imediatamente. Os pais e responsáveis são
  encorajados a monitorar as atividades online de seus filhos e orientá-los no
  uso responsável de plataformas digitais.
</p>
<p class="context">d) Resposta à Coleta Inapropriada</p>
<p class="context">
  Se for descoberto que inadvertidamente coletamos dados pessoais de um menor de
  idade, tomaremos medidas apropriadas para remover esses dados dos registros da
  Plataforma MZIC. Isso também pode exigir a exclusão da conta na Plataforma
  MZIC associada a esses dados.
</p>
<p class="context">e) Contas Compartilhadas</p>
<p class="context">
  A Empresa entende que em alguns casos contas podem ser compartilhadas entre
  membros da família ou amigos. Os Usuários devem estar cientes de que
  compartilhar o acesso à conta na Plataforma MZIC acarreta riscos, incluindo a
  exposição potencial a conteúdo ou ações inapropriadas. Se o Usuário
  compartilha sua conta com menores de idade, é responsável por supervisionar
  suas atividades na Plataforma MZIC. Se o Usuário tiver alguma dúvida ou
  preocupação sobre a política de dados de menores de idade da Plataforma MZIC
  ou precisar relatar uma violação suspeita, entre em contato diretamente com a
  Plataforma MZIC, que está aqui para garantir a segurança e privacidade de
  todos os Usuários, independentemente da idade.
</p>

<p class="title">13. APLICATIVOS DE TERCEIROS</p>
<p class="context">
  A Plataforma MZIC recebe dados pessoais de titulares dos seguintes aplicativos
  e/ou fontes de terceiros:
</p>
<p class="context">
  a) Aplicativos de Terceiros que o Usuário utiliza para se conectar com a
  Plataforma MZIC
</p>
<p class="context">
  Se o Usuário conectar sua conta na Plataforma MZIC através de um aplicativo,
  serviço ou dispositivo de terceiros, a Plataforma MZIC pode coletar e usar
  informações deles. Na maioria dos casos, a Plataforma MZIC precisa dessas
  informações para fornecer os serviços ao Usuário e possibilitar qualquer
  integração. Esses aplicativos, serviços ou dispositivos de terceiros podem
  incluir: plataformas de mídia social e dispositivos, incluindo áudio (por
  exemplo, alto-falantes ou fones de ouvido), smartwatches, smart TVs, telefones
  celulares e tablets, automotivos (por exemplo, CarPlay), consoles de jogos.
  Categorias de dados: Dados do Usuário, Dados de Uso.
</p>
<p class="context">b) Parceiros de Serviço Técnico</p>
<p class="context">
  Os parceiros de serviço técnico da Plataforma MZIC fornecem dados sobre o uso
  e as interações do Usuário com os serviços da Plataforma MZIC. Isso ajuda ao
  aprimoramento da funcionalidade e melhora a experiência do Usuário na
  Plataforma MZIC. Isso inclui mapear endereços IP para dados de localização não
  precisos (por exemplo, país ou região, cidade, estado). A Plataforma MZIC
  também trabalha com provedores de serviços de segurança que ajudam a proteger
  as contas do usuário da Plataforma MZIC. Categorias de dados: Dados do
  Usuário, Dados de Uso.
</p>
<p class="context">c) Parceiros de Pagamentos</p>
<p class="context">
  Os parceiros de pagamento da Plataforma MZIC compartilham informações sobre as
  transações dos Usuários. Isso garante operações financeiras sem problemas e
  nos ajuda a resolver quaisquer problemas relacionados ao pagamento. Os dados
  coletados podem incluir detalhes da transação (por exemplo, data, hora, valor
  e método de pagamento), endereço de cobrança e consultas de suporte ao Usuário
  relacionadas a transações. Categorias de dados: Dados dos métodos de
  pagamentos, Dados de transações.
</p>
<p class="context">d) Parceiros de Marketing e Publicidade</p>
<p class="context">
  A Plataforma MZIC recebe inferências de seus parceiros de marketing sobre suas
  campanhas publicitárias. Essas inferências são compreensões dos parceiros
  sobre os interesses e preferências dos Usuários. Isso ajuda a Plataforma MZIC
  a fornecer informações mais relevantes para o Usuário. Categorias de dados:
  Dados de Uso.
</p>
<p class="context">e) Empresas Adquiridas</p>
<p class="context">
  Se a Empresa adquirir outras empresas ou passar por um processo de fusão, a
  Plataforma MZIC pode receber dados pessoais sobre o Usuário dessas empresa.
  Isso é para aprimorar os serviços da Plataforma MZIC e a experiência do
  Usuário. Categorias de dados: Dados do Usuário, Dados de Uso.
</p>

<p class="title">14. COOKIES</p>
<p class="context">a) O que são Cookies?</p>
<p class="context">
  Os cookies são pequenos pacotes de dados armazenados em arquivos de texto no
  dispositivo do Usuário (como um computador, celular ou tablet) quando o
  Usuário visita determinadas páginas da web. Esses arquivos de dados contêm
  informações sobre as preferências de navegação, configurações e histórico de
  navegação do Usuário. Essas informações permitem que a Plataforma MZIC
  reconheça o Usuário quando interage ou revisita os serviços da Plataforma MZIC
  e personalize a experiência de navegação de acordo com as preferências do
  Usuário. Existem dois tipos de cookies: cookies de sessão e cookies
  persistentes. Os cookies de sessão são temporários e expiram quando você fecha
  seu navegador da web. Os cookies persistentes, por outro lado, permanecem em
  seu navegador por um período determinado de tempo ou até você optar por
  excluí-los. Os cookies utilizados na Plataforma MZIC podem ser servidos
  diretamente pela Plataforma MZIC, conhecidos como "cookies primários” ou por
  terceiros, conhecidos como "cookies de terceiros”. Os cookies primários ajudam
  a Plataforma MZIC a lembrar as configurações e preferências do Usuário, como
  seleção de idioma e localização. A Plataforma MZIC também integra cookies de
  análise de terceiros, como o Google Analytics, para ajudar a Plataforma MZIC a
  entender como o Usuário está usando a Plataforma MZIC, permitindo o
  aprimoramento contínuo da experiência do Usuário. Mais informações gerais
  sobre cookies podem ser encontradas em https://www.youronlinechoices.eu.
</p>
<p class="context">b) Como a Plataforma MZIC usa os cookies?</p>
<p class="context">
  Os cookies desempenham várias funções diferentes, como permitir que o Usuário
  navegue entre páginas de forma eficiente, lembrar suas preferências, garantir
  a segurança de seus dados, ajudar o Usuário a personalizar sua experiência e,
  de forma geral, melhorar sua interação com os serviços da Plataforma MZIC.
  Categoria de cookie: Essencial Como a Plataforma MZIC usa esses cookies? Esses
  cookies são vitais para o funcionamento adequado dos serviços da Plataforma
  MZIC. Eles permitem funcionalidades essenciais, como login de Usuários,
  gerenciamento de contas e manutenção da segurança da Plataforma MZIC. Se esses
  cookies forem desativados, isso pode afetar o desempenho ou a funcionalidade
  dos serviços da Plataforma MZIC e algumas áreas podem se tornar inacessíveis.
  Categoria de cookie: Segurança Como a Plataforma MZIC usa esses cookies? A
  Plataforma MZIC utiliza esses cookies para garantir sua segurança e
  integridade e dos dados dos Usuários. Eles ajudam a Plataforma MZIC a
  identificar e mitigar potenciais riscos de segurança. A ausência desses
  cookies pode afetar a segurança da Plataforma MZIC e expô-la a certas ameaças.
  Categoria de cookie: Preferências Como a Plataforma MZIC usa esses cookies? A
  Plataforma MZIC utiliza esses cookies para lembrar as configurações e
  preferências individuais do Usuário, como seu idioma e localização escolhidos
  ao utilizar os serviços da Plataforma MZIC. Esses cookies ajudam a
  personalizar a experiência do Usuário, tornando suas interações mais
  eficientes e significativas. Categoria de cookie: Analíticos Como a Plataforma
  MZIC usa esses cookies? A Plataforma MZIC utiliza esses cookies para obter
  insights sobre como o Usuário interage com os serviços da Plataforma MZIC.
  Isso inclui entender a popularidade de determinados conteúdos, determinar se o
  Usuário interagiu com recursos específicos e identificar áreas que necessitam
  de melhorias. Os dados coletados por esses cookies ajudam a aprimorar os
  Serviços da Plataforma MZIC e oferecer uma melhor experiência ao Usuário.
  Categoria de cookie: Publicidade Como a Plataforma MZIC usa esses cookies? A
  Plataforma MZIC e seus parceiros de publicidade confiáveis utilizam esses
  cookies para exibir anúncios mais relevantes para o Usuário. Eles ajudam a
  Plataforma MZIC a acompanhar a eficiência de suas campanhas publicitárias,
  tanto nos serviços da Plataforma MZIC quanto em outros websites, e
  proporcionar ao Usuário uma experiência publicitária mais personalizada.
</p>
<p class="context">c) Gerenciando as Preferências de Cookies do Usuário</p>
<p class="context">
  O Usuário tem o direito de escolher se deseja aceitar ou não os cookies. No
  entanto, como os cookies podem ser uma parte importante do funcionamento dos
  serviços da Plataforma MZIC, se o Usuário remover ou rejeitar os cookies, isso
  pode afetar a disponibilidade e funcionalidade dos serviços da Plataforma
  MZIC. Abaixo estão algumas opções para controlar ou limitar como os cookies
  são usados na Plataforma MZIC: O Usuário geralmente pode gerenciar
  configurações para remover ou rejeitar cookies do navegador manualmente dentro
  das configurações de configuração do seu navegador. Para gerenciar essas
  configurações, o Usuário deve seguir as instruções fornecidas pelo seu
  navegador; Para evitar que os dados do Usuário sejam usados pelo Google
  Analytics, o Usuário pode instalar o complemento de navegador de exclusão do
  Google. Para obter informações sobre como os parceiros de publicidade da
  Plataforma MZIC podem permitir que o Usuário opte por não receber anúncios com
  base em seu histórico de navegação na web, visite http://optout.aboutads.info/
</p>
<p class="context">d) Alterações a esta Política de Cookies</p>
<p class="context">
  A Empresa pode atualizar ou alterar esta Política de Cookies de tempos em
  tempos. Se for feita alguma atualização ou alteração, ela será disponibilizada
  no site em https://mzic.io/privacy e será também atualizada a data efetiva no
  topo desta Política de Privacidade. Também notificaremos o Usuário enviando um
  e-mail para o endereço que o Usuário forneceu no momento do registro ou
  através de outros canais de comunicação apropriados. A utilização contínua
  pelo Usuário dos serviços da Plataforma MZIC após quaisquer dessas mudanças
  constitui sua aceitação da nova Política de Cookies. Se o Usuário não concorda
  com algum destes termos, não deve usar ou acessar (ou continuar acessando) os
  serviços da Plataforma MZIC.
</p>

<p class="title">15. TRANSFERÊNCIA INTERNACIONAL DE DADOS</p>
<p class="context">
  Como a Empresa é sediada nos Estados Unidos da América, os dados coletados e
  tratados serão armazenados nos servidores da Amazon AWS (www.aws.amazon.com),
  de acordo com os maiores padrões de segurança existentes, conforme mencionado
  no item 13 acima. Além desse fato, poderá ocorrer a transferência
  internacional de dados entre a sede da Empresa nos Estados Unidos da América e
  suas empresas coligadas, filiais e/ou sucursais no Brasil.
</p>

<p class="title">16. ATUALIZAÇÕES NAS POLÍTICAS DE PRIVACIDADE</p>
<p class="context">
  Este documento contendo as Políticas de Privacidade da Plataforma MZIC teve
  sua última modificação em 09/02/2024. As Políticas de Privacidade contidas
  neste documento poderão sofrer alterações e atualizações para aprimoramento a
  qualquer momento, ficando resguardado à Empresa o pleno direito a tais
  alterações e atualizações. Por isso, a Empresa convida todos os usuários da
  Plataforma MZIC a acessarem com regularidade a página contendo as Políticas de
  Privacidade a fim de estarem cientes das referidas alterações e atualizações.
</p>
