// src/app/guards/language.guard.ts
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LanguageService } from '@mzic/mzic-services';

export const languageGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const languageService = inject(LanguageService);
  const browserLang = languageService.getBrowserLanguage();

  if (browserLang.startsWith('pt')) {
    router.navigate(['/pt-br/terms-of-use']);
  } else {
    router.navigate(['/en/terms-of-use']);
  }

  // Retorna false porque o redirecionamento já ocorreu
  return false;
};
