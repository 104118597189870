import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import {
  ResponseInterface,
  TrackResourceForm,
  TrackResourceItem,
  TracksResourceData,
} from '@mzic/mzic-interfaces';
import { deleteNullProperties } from '@mzic/mzic-utils';
import { MzicArtistLocalService } from '../../mzic-artist-local/mzic-artist-local.service';

@Injectable({
  providedIn: 'root',
})
export class TrackService {
  private apiUrl = environment.apiUrl;

  headers = new HttpHeaders({
    Accept: '*/*',
    'TEAM-ID': this.mzicArtistLocalService.getTeamId(),
  });

  constructor(
    private readonly _http: HttpClient,
    private mzicArtistLocalService: MzicArtistLocalService,
  ) {}

  getTracks() {
    return this._http.get<ResponseInterface<TracksResourceData>>(
      `${this.apiUrl}/api/distribution/track/v2/find?size=300`, // Enquanto não temos paginação, vamos pegar 300
      { headers: this.headers },
    );
  }

  getTrackById(id: string) {
    return this._http.get<TrackResourceItem>(
      `${this.apiUrl}/api/distribution/track/v2/find/${id}`,
      { headers: this.headers },
    );
  }

  updateTrack(track: Partial<TrackResourceForm>, trackId: number) {
    // Garante de excluir propriedades que estão nulas (para não quebrar a API)
    deleteNullProperties(track);

    return this._http.put<any>(
      `${this.apiUrl}/api/distribution/track/v2/update/${trackId}`,
      track,
      { headers: this.headers },
    );
  }
}
