import { ResponseInterfacePageable } from '../http/response.interface';
import { ReleaseItem } from './release-resource.interface';

export interface TrackResourceItem {
  timestamp: string;
  status: string;
  data: TrackResourceItemData;
}

export interface TrackResourceItemData {
  id: number;
  title: string;
  titleVersion?: string;
  primaryGenre: Genre;
  secondaryGenre?: Genre;
  isrc?: string;
  explicit: boolean;
  instrumental: boolean;
  artists: ArtistItem[];
  writers: ArtistItem[];
  languages?: LanguageItem[];
  filePathLocation: string;
  hash: string;
  externalFileHash: string;
  duration: number;
  fileStatus: string;
  artistNames: string;
  lyrics?: string;
  lyricsLanguageId?: number;
  type: TrackTypeEnum;
  properties: PropertiesEnum[];
}

export interface Genre {
  id: number;
  name: string;
}

export interface ArtistItem {
  artist: Artist;
  artistType: ArtistType;
  mainArtist: boolean;

  // Dados especificos de um writer/publisher
  rightsId?: number;
  sharePercentage?: number;
  publisherName?: string;
}

export interface Artist {
  id: number;
  name: string;
  lastName?: string;
  phoneNumber?: string;
  birthDate?: string;
  email: string;
  photo?: string;
  uuidViberate?: string;
  artistAppleId?: string;
  artistSpotifyId?: string;
  artistExternalId?: number;
  socialSecurity?: string;
}

export interface ArtistType {
  id: number;
  roleId: number;
  name: string;
  priority: number;
}

export interface LanguageItem {
  title?: string;
  language: Language;
}

export interface Language {
  id: number;
  name?: string;
  code?: string;
  flagUrl?: string;
}

/**
 * Interface necessária para PUT da informações da track
 */
export interface TrackResourceForm {
  title: string;
  titleVersion: string;
  primaryGenreId: number;
  secondaryGenreId: number;
  isrc: string;
  explicit: boolean;
  lyricsLanguageId: number;
  lyrics: string;
  instrumental: boolean;
  startPreview: number;
  artists: TrackArtist[];
  writers: TrackWriter[];
  languages: TrackLanguage[];
  type: TrackTypeEnum;
  properties: PropertiesEnum[];
  labelId?: number;
}

export interface TrackArtist {
  artistId: number;
  artistTypeId: number;
  mainArtist: boolean;
}

export interface TrackWriter {
  artistId: number;
  artistTypeId: number;
  sharePercentage: number;
  rightsId: number;
  publisherName?: string;
}

export interface TrackLanguage {
  title?: string;
  languageId: number;
}

export interface TracksResourceData extends ResponseInterfacePageable {
  content: TrackSimpleReadResponse[];
}

/**
 * Interface de uma track retornado na listagem de find de tracks
 */
export interface TrackSimpleReadResponse {
  id: number;
  title: string;
  titleVersion?: string;
  primaryGenre: Genre;
  secondaryGenre?: Genre;
  isrc?: string;
  hash?: string;
  releases: ReleaseItem[];
  artists: ArtistItem[];
  firstReleaseHash?: string;
  fileStatus: FileStatusEnum;
  status: TrackStatusEnum;
  trackSamples: TrackSample[];
  duration: number;
}

export interface TrackReadResponse extends TrackSimpleReadResponse {
  trackExternalId: number;
  explicit: boolean;
  lyricsLanguageId: number;
  lyrics: string;
  trackTypeId: number;
  instrumental: boolean;
  startPreview: number;
  writers: ArtistItem[];
  languages: LanguageItem[];
  filePathLocation: string;
  externalFileHash: string;
  artistNames: string;
  type: TrackTypeEnum;
  properties: PropertiesEnum[];
  label: LabelResponse;
}

export interface TrackSample {
  id: number;
  title: string;
  isrc: string;
  releaseDate: string;
  durationMs: number;
  label: string;
  dbBeginTimeOffsetMs: number;
  dbEndTimeOffsetMs: number;
}

export interface LabelResponse {
  id: number;
  imageId: number;
  name: string;
  description: string;
}

export enum TrackTypeEnum {
  ORIGINAL_SONG = 'ORIGINAL_SONG',
  COVER_SONG = 'COVER_SONG',
  PUBLIC_DOMAIN_SONG = 'PUBLIC_DOMAIN_SONG',
}

export enum PropertiesEnum {
  NONE = 'NONE',
  REMIX = 'REMIX',
  SAMPLES_OR_STOCK = 'SAMPLES_OR_STOCK',
  MIX_OR_COMPILATION = 'MIX_OR_COMPILATION',
  ALTERNATE_VERSION = 'ALTERNATE_VERSION',
  SPECIAL_GENRE = 'SPECIAL_GENRE',
  NON_MUSICAL_CONTENT = 'NON_MUSICAL_CONTENT',
  INCLUDES_AI = 'INCLUDES_AI',
}

export enum FileStatusEnum {
  CLEAN = 'CLEAN',
  WARNING = 'WARNING',
  PROCESSING = 'PROCESSING',
}

export enum TrackStatusEnum {
  READY = 'READY',
  IN_INSPECTION = 'IN_INSPECTION',
  DISTRIBUTED = 'DISTRIBUTED',
}
