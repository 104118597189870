<article>
  <p class="title">MZIC Platform Terms of Use</p>
  <p class="title">Last Updated: August 8, 2024</p>

  <p class="context">
    Welcome to
    <a target="_blank" href="https://mzic.io">https://mzic.io.</a>
    Please read these Terms of Service (the
    <b>Terms</b>
    ) carefully. This is an agreement between you (
    <b>“User"</b>
    ,
    <b>“you”</b>
    or similar terms) and MZIC LLC (
    <b>“MZIC"</b>
    ,
    <b>“we”</b>
    , or similar terms) (each a
    <b>“Party”</b>
    to this agreement and collectively the
    <b>"Parties"</b>
    ). These Terms govern your use of the MZIC website located at
    <a target="_blank" href="https://mzic.io">https://mzic.io</a>
    (the
    <b>“Site”</b>
    ), MZIC for Artists, and all related tools, mobile applications (
    <b>“App”</b>
    ), web applications, decentralized applications, smart contracts, APIs, or
    other platforms, applications, or services offered or associated with MZIC
    (collectively the
    <b>“Plataforma MZIC”</b>
    ). For ease of reading, the Site, MZIC for Artists, our services, App, and
    MZIC Platform are collectively referred to as the
    <b>"Services.”</b>
  </p>

  <p class="paragraph">
    <span>1</span>
    Acceptance of Terms
  </p>

  <p class="context">
    <span>1.1</span>
    By continuing to browse, access, and use our Services or by obtaining,
    maintaining, or possessing a token of ownership rights over content
    ("Fractional Token"), you acknowledge that you have read and accept the
    terms and conditions set forth below. If you do not accept, you must
    immediately cease accessing and using our Services.
  </p>

  <p class="context">
    <span>1.2</span>
    If you choose to accept these Terms, you must do so as presented to you—we
    will not accept changes. If any future changes are unacceptable to you, you
    must discontinue using the Services. Your continued use of the Services now
    or after the posting and reasonable notice of such changes will indicate
    your acceptance of these Terms and any modifications.
  </p>

  <p class="paragraph">
    <span>2</span>
    Additional Terms and Policies
  </p>

  <p class="context">
    <span>2.1</span>
    Your use of the Services is subject to additional terms and policies that
    may be posted on the Services from time to time, including the following:
  </p>

  <p class="context">
    <span>2.2</span>
    <b class="underline">Privacy Policy:</b>
    Please read our Privacy Policy available at
    <a target="_blank" href="https://legal.mzic.io/en/privacy">
      https://legal.mzic.io/privacy
    </a>
    , which also governs your use of the Services. By submitting personal data
    through our Services, you agree to the terms of our Privacy Policy and
    expressly consent to the collection, use, and disclosure of your personal
    data in accordance with the Privacy Policy.
  </p>

  <p class="context">
    <span>2.3</span>
    <b class="underline">Associated Risks:</b>
    Using the Services, including the trading of Fractional Tokens, may involve
    significant risks. Some of these risks are discussed here, but you should
    review our page on Associated Risks of Fractional Tokens available at
    <a target="_blank" href="https://legal.mzic.io/en/risks">
      https://legal.mzic.io/risks
    </a>
    for more information about these risks.
  </p>

  <p class="context">
    <span>2.4</span>
    <b class="underline">Artist and User Terms:</b>
    If you are an Artist or a User, you will be subject to the following terms
    as applicable:
  </p>

  <p class="context">
    <span>2.4.1</span>
    <b class="underline">Artists:</b>
    You will be subject to the product terms of rights sales governed by the
    Agency Contract for Transfer of Content Ownership Rights, digital
    distribution terms governed by the Digital Distribution Terms, and the
    Royalty Split Distribution Agreement according to the products you contract
    from MZIC.
  </p>

  <p class="context">
    <span>2.4.2</span>
    <b class="underline">Users:</b>
    You will be subject to the product terms of rights acquisition governed by
    the Content Ownership Rights Acquisition Contract according to the products
    you contract from MZIC.
  </p>

  <p class="context">
    <span>2.5</span>
    <b class="underline">Third-Party Service Providers:</b>
    We partner with third parties to assist with payments, transactions,
    identity verification, fraud prevention, and other legal and compliance
    functions. By using our Services, you agree to:
  </p>

  <p class="context">
    <span>2.5</span>
    <b class="underline">Provedores de Serviços Terceirizados:</b>
    Nós fazemos parcerias com terceiros para auxiliar em pagamentos, transações,
    verificação de identidade, prevenção de fraude e outras funções legais e de
    conformidade. Ao usar nossos Serviços, você concorda com:
  </p>

  <p class="context">
    <span>2.5.1</span>
    ZOOP TECNOLOGIA E INSTITUIÇÃO DE PAGAMENTO S.A.
    <a
      target="_blank"
      href="https://blog.zoop.com.br/termos-e-condicoes-de-uso-do-sistema-zoop">
      Terms of Use
    </a>
    e
    <a target="_blank" href="https://www.zoop.com.br/politicas/privacidade">
      Privacy Policy
    </a>
  </p>

  <p class="context">
    <span>2.5.2</span>
    Plaid Technologies, Inc.
    <a
      target="_blank"
      href="https://plaid.com/legal/#end-user-services-agreement-us">
      Terms of Use
    </a>
    e
    <a target="_blank" href="https://plaid.com/legal/#end-user-privacy-policy">
      Privacy Policy
    </a>
  </p>

  <p class="paragraph">
    <span>3</span>
    Account Registration and Management
  </p>

  <p class="context">
    <span>3.1</span>
    <b class="underline">Account Creation and Setup:</b>
    To use the MZIC Platform and interact with its services, users must first
    create an account by providing accurate and current personal information
    such as legal name, email address, and phone number. After creating the
    account, users must complete the verification process (AML and KYC), which
    may require a government-issued ID.
  </p>

  <p class="context">
    <span>3.2</span>
    <b class="underline">Account Security:</b>
    Users are responsible for maintaining the security of their account and
    associated Smart Digital Account, including the confidentiality of their
    login credentials. Any suspicious activity or security breaches must be
    reported immediately. Users must also ensure their devices are secure by
    keeping the operating system, software, and antivirus protections up to
    date.
  </p>

  <p class="context">
    <span>3.3</span>
    <b class="underline">Smart Digital Account:</b>
    As part of MZIC Platform's commitment to decentralization and user control,
    the MZIC Platform has adopted Smart Account technology based on the ERC-4337
    standard. A Smart Digital Account is generated for each user upon creating
    an account on the MZIC Platform. This account serves as a repository for any
    Fractional Tokens a user may hold. All token transactions are conducted
    through this Smart Digital Account, representing the blockchain address
    where the tokens will be stored and transacted. MZIC acts only as the
    subscriber of transactions users perform, not as the custodian of the
    tokens.
  </p>

  <p class="paragraph">
    <span>4</span>
    User Conduct and Obligations
  </p>

  <p class="context">
    <span>4.1</span>
    <b class="underline">Code of Conduct:</b>
    Users will use the MZIC Platform and its services only for legitimate and
    lawful purposes. Users will not use the MZIC Platform to engage in any
    fraudulent or malicious activities, including but not limited to identity
    fraud, misrepresentation, money laundering, or other deceptive behavior.
  </p>

  <p class="context">
    <span>4.2</span>
    <b class="underline">Compliance with Laws:</b>
    As a user of the MZIC Platform, you are required to comply with all
    applicable laws, regulations, and rules, including but not limited to those
    related to financial transactions, copyrights, online conduct, and taxation.
  </p>

  <p class="paragraph">
    <span>5</span>
    MZIC Platform Services
  </p>

  <p class="context">
    MZIC offers two main products: the Content Ownership Rights Marketplace and
    MZIC For Artists. Each has specific scopes and offers different
    functionalities to its users.
  </p>

  <p class="context">
    <span>5.1</span>
    <b>Content Ownership Rights Marketplace</b>
  </p>

  <p class="context">
    <span>5.1.1</span>
    <b class="underline">Music Tokens and Fractional Tokens:</b>
    MZIC uses blockchain technology to create unique tokens representing rights
    over content. These tokens allow the digitization and fractionation of
    ownership rights over content, providing users and rights holders with a new
    way to monetize their work and offering users and rights holders a unique
    opportunity to invest in content rights.
  </p>

  <p class="context">
    <span>5.1.2</span>
    <b class="underline">Transaction Processing and Fees:</b>
    All transactions on the MZIC Platform, including the acquisition or
    disposition of Fractional Tokens, are processed through the Polygon
    blockchain. However, actual payments for these transactions are processed
    off-chain through third-party Payment Processing services.
  </p>

  <p class="context">
    <span>5.1.3</span>
    <b class="underline">MZIC Platform Intermediation:</b>
    The MZIC Platform serves as a marketplace for the acquisition, disposition,
    and trading of Fractional Tokens associated with specific Music Tokens.
    These transactions allow users to acquire or dispose of their participation
    in specific content subject to the terms set forth in these Terms of Use and
    the Content Rights Acquisition Contract.
  </p>

  <p class="context">
    <span>5.1.4</span>
    <b class="underline">Royalty Collection and Distribution:</b>
    The MZIC Platform collects royalties from DSPs on behalf of rights holders.
    The MZIC Platform maintains the status of a claimant of copyright ownership
    rights for regulatory compliance and royalty collection purposes.
  </p>

  <p class="context">
    <span>5.2</span>
    <b>MZIC For Artists</b>
  </p>

  <p class="context">
    <span>5.2.1</span>
    <b class="underline">Sound Recording Rights Sales:</b>
    MZIC allows artists to create sound recording rights sale offers, enabling
    them to monetize their works directly to investors and fans.
  </p>

  <p class="context">
    <span>5.2.2</span>
    <b class="underline">Digital Distribution:</b>
    MZIC offers digital distribution services, allowing artists to distribute
    their music to various DSPs (Digital Service Providers) efficiently and
    automatically.
  </p>

  <p class="context">
    <span>5.2.3</span>
    <b class="underline">Royalty Split:</b>
    MZIC facilitates royalty splitting between artists and other rights holders,
    ensuring a fair and transparent distribution of earnings generated by the
    works.
  </p>

  <p class="paragraph">
    <span>6</span>
    <b class="underline">Intellectual Property Rights</b>
  </p>

  <p class="context">
    <span>6.1</span>
    <b class="underline">Intellectual Property – MZIC Platform:</b>
    All rights relating to the MZIC Platform (excluding User Content) are and
    will remain the exclusive property of the MZIC Platform and its owners. The
    MZIC Platform is protected by copyright laws, software rights, industrial
    property rights, and other laws of Brazil and international treaties.
  </p>

  <p class="context">
    <span>6.2</span>
    <b class="underline">User Content and Copyright Infringement:</b>
    When users upload, send, store, transmit, or receive User Content to or
    through the MZIC Platform, users grant the MZIC Platform a worldwide,
    royalty-free, perpetual, irrevocable, non-exclusive, transferable, and
    sublicensable license to use, copy, modify, create derivative works from,
    distribute, publicly display, publicly perform, and otherwise exploit User
    Content in whole or in part for the purpose of operating, developing,
    providing, promoting, and improving the MZIC Platform and researching and
    developing new features.
  </p>

  <p class="paragraph">
    <span>7</span>
    <b>Service Limitations and Disclaimers</b>
  </p>

  <p class="context">
    <span>7.1</span>
    <b class="underline">Service Availability:</b>
    MZIC will make reasonable efforts to provide MZIC Platform services and make
    the MZIC Platform available to users 24 hours a day, 7 days a week, but
    cannot guarantee uninterrupted or flawless operation.
  </p>

  <p class="context">
    <span>7.2</span>
    <b class="underline">Limitations of Liability:</b>
    MZIC is not an investment advisor and does not provide any form of
    investment advice. All information and resources provided on the MZIC
    Platform are strictly for informational purposes and should not be construed
    as investment advice or a recommendation to engage in any form of investment
    activity.
  </p>

  <p class="paragraph">
    <span>8</span>
    <b>Dispute Resolution and Applicable Law</b>
  </p>

  <p class="context">
    <span>8.1</span>
    <b class="underline">Applicable Law:</b>
    These Terms of Use and your use of the MZIC Platform will be governed and
    interpreted in accordance with the laws of Brazil.
  </p>

  <p class="context">
    <span>8.2</span>
    <b class="underline">Dispute Resolution and Legal Aspects:</b>
    Any questions regarding the legal aspects of the MZIC Platform can be
    communicated to
    <a href="mailto:legal@mzic.io.">legal&#64;mzic.io.</a>
  </p>

  <p class="paragraph">
    <span>9</span>
    <b>Contract Modifications</b>
  </p>

  <p class="context">
    <span>9.1</span>
    <b class="underline">Modification of Contract:</b>
    MZIC reserves the right, at its sole discretion, to modify or replace these
    Terms of Use at any time. If a revision is of an essential nature, the MZIC
    Platform will provide at least 30 (thirty) days’ notice before any new terms
    take effect.
  </p>

  <p class="context">
    <span>9.2</span>
    <b class="underline">User Notification:</b>
    MZIC will make efforts to notify you of any changes to the Terms of Use by
    sending an email to the address you provided at registration, providing
    notice through the MZIC Platform, or through other appropriate communication
    channels.
  </p>

  <p class="paragraph">
    <span>10</span>
    <b>Contact Information</b>
  </p>

  <p class="context">
    If you have any questions about these Terms of Use or wish to report
    violations of these Terms of Use, please contact us at:
  </p>

  <p class="title">
    MZIC LLC.
    <br />
    13537 Kitty Fork Road, 32828 Orlando, FL, EUA.
    <br />
    <a href="mailto:legal@mzic.io.">legal&#64;mzic.io.</a>
  </p>
</article>
