import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'mzic-privacy-en',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './privacy-en.component.html',
  styleUrl: './styles.scss',
})
export class PrivacyEnComponent {}
