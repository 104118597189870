import { Route } from '@angular/router';
import { AppComponent } from './app.component';
import { languageGuard } from './guards/language.guard';
import { ShellComponent } from './pages/shell/shell.component';

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    component: AppComponent,
    canActivate: [languageGuard],
  },
  {
    path: 'terms-of-use',
    component: ShellComponent,
  },
  {
    path: 'privacy',
    component: ShellComponent,
  },
  {
    path: ':lang/:document',
    component: ShellComponent,
    title: (param) => {
      const lang = param.params['lang'];
      const document = param.params['document'];

      if (lang === 'pt-br' && document === 'terms-of-use') {
        return 'MZIC - Termos de uso';
      }

      if (lang === 'en' && document === 'terms-of-use') {
        return 'MZIC - Terms of Use';
      }

      if (lang === 'en' && document === 'privacy') {
        return 'MZIC - Privacy Policy';
      }

      if (lang === 'pt-br' && document === 'privacy') {
        return 'MZIC - Política de privacidade';
      }

      return 'MZIC';
    },
  },
];
