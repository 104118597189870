import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParamsLoginUser, ResponseUserLogin } from '@mzic/mzic-interfaces';
import {
  API_URL,
  ConfirmationTypeEnum,
  MeanTypeEnum,
  UrlParams,
} from '@mzic/mzic-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MzicAuthUserService {
  constructor(private readonly _http: HttpClient) {}

  public authenticateUser(
    params: ParamsLoginUser,
  ): Observable<ResponseUserLogin> {
    return this._http.post<ResponseUserLogin>(
      API_URL.USER_ARTIST_SIGN_IN,
      params,
    );
  }

  public logOutUser(): Observable<void> {
    return this._http.post<void>(API_URL.USER_ARTIST_SIGN_OUT, {});
  }

  public confirmCode(
    payload: {
      userId: number;
      code: string;
    },
    typeConfirmCode: {
      meanType: MeanTypeEnum;
      confirmationType: ConfirmationTypeEnum;
    },
  ): Observable<ResponseUserLogin> {
    const query = UrlParams(payload);
    return this._http.post<ResponseUserLogin>(
      API_URL.CONFIRM_CODE(
        typeConfirmCode.meanType,
        typeConfirmCode.confirmationType,
      ) + query,
      {},
    );
  }

  public resendVerificationCode(
    payload: {
      email: string;
      username: string;
    },
    typeConfirmCode = {
      meanType: MeanTypeEnum.EMAIL,
      confirmationType: ConfirmationTypeEnum.MFA,
    },
    body?: { newEmail: string } | { newPhoneNumber: string } | null,
  ): Observable<void> {
    const query = UrlParams(payload);
    return this._http.post<void>(
      API_URL.RESEND_CONFIRM_CODE(
        typeConfirmCode.meanType,
        typeConfirmCode.confirmationType,
      ) + query,
      body || {},
    );
  }
}
