import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import {
  CreateWriterRequest,
  RecentArtist,
  ResponseInterface,
} from '@mzic/mzic-interfaces';
import { Observable } from 'rxjs';
import { MzicArtistLocalService } from '../../mzic-artist-local/mzic-artist-local.service';

@Injectable({
  providedIn: 'root',
})
export class WriterService {
  private apiUrl = environment.apiUrl;

  headers = new HttpHeaders({
    Accept: '*/*',
    'TEAM-ID': this.mzicArtistLocalService.getTeamId(),
  });

  constructor(
    private readonly _http: HttpClient,
    private mzicArtistLocalService: MzicArtistLocalService,
  ) {}

  createWriter(
    data: CreateWriterRequest,
  ): Observable<ResponseInterface<RecentArtist>> {
    const url = `${this.apiUrl}/api/distribution/artist/v2/writer/create`;
    return this._http.post<ResponseInterface<RecentArtist>>(url, data, {
      headers: this.headers,
    });
  }
}
