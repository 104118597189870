<p class="title">MZIC Platform Privacy Policy</p>
<p class="context">Last Update: February 20th 2024.</p>
<p class="title">1. INTRODUCTION</p>
<p class="context">
  MZIC Platform is an application owned by MZIC LLC a legal entity established
  under the laws of the United States of America ("USA") with its headquarters
  at 13537 Kitty Fork Road 32828 Orlando FL USA. The company is registered with
  the Employer Identification Number under number 93-1940892 with the Internal
  Revenue Service of the USA represented herein by its constituent acts
  ("Company").
</p>
<p class="context">
  The Company values privacy user information and data on MZIC Platform.
  Therefore this document containing the Privacy Policy of MZIC Platform aims to
  clarify to the users of MZIC Platform what information is collected how and by
  whom it is handled how it is shared and how the security of this information
  is ensured in order to maintain a relationship of absolute transparency.
</p>
<p class="context">
  THE COMPANY DECLARES THAT IT DOES NOT COLLECT OR PROCESS SENSITIVE PERSONAL
  DATA.
</p>
<p class="context">Applicability</p>
<p class="context">
  The Privacy Policy of MZIC Platform applies to all data subjects (natural
  persons) who interact with MZIC Platform.
</p>
<p class="title">2. LEGAL DEFINITIONS</p>
<p class="context">
  For the purposes of this document containing the Privacy Policy of MZIC
  Platform the following definitions are used in accordance with Brazilian
  General Data Protection Law (Law 13.709/2018 - "LGPD"):
</p>
<p class="context">
  "Anonymization": the use of reasonable and available technical means at the
  time of processing through which data loses the possibility of association
  directly or indirectly with an individual;
</p>
<p class="context">
  "Anonymized data": data relating to a data subject that cannot be identified
  considering the use of reasonable and available technical means at the time of
  its processing;
</p>
<p class="context">
  "Blocking": temporary suspension of any processing operation by storing
  personal data or the database;
</p>
<p class="context">
  "Consent": free informed and unequivocal manifestation by which the data
  subject agrees to the processing of their personal data for a specific
  purpose;
</p>
<p class="context">
  "Controller": a natural or legal person of public or private nature to whom
  decisions regarding the processing of personal data belong;
</p>
<p class="context">
  "Data processing agents": the controller and the processor;
</p>
<p class="context">
  "Data protection officer": a person appointed by the controller and processor
  to act as a communication channel between the controller data subjects and the
  National Data Protection Authority (ANPD);
</p>
<p class="context">
  "Data protection impact report": controller documentation containing a
  description of the personal data processing processes that may pose risks to
  civil liberties and fundamental rights as well as measures safeguards and risk
  mitigation mechanisms;
</p>
<p class="context">
  "Data sharing": communication dissemination international transfer
  interconnection of personal data or shared processing of personal databases by
  public bodies and entities in compliance with their legal competencies or
  between them and private entities reciprocally with specific authorization for
  one or more processing modalities permitted by these public entities or
  between private entities;
</p>
<p class="context">
  "Data subject": a natural person to whom the personal data being processed
  refers;
</p>
<p class="context">
  "Database": a structured set of personal data established in one or more
  places in electronic or physical support;
</p>
<p class="context">
  "Deletion": deletion of data or set of data stored in a database regardless of
  the procedure employed;
</p>
<p class="context">
  "International data transfer": transfer of personal data to a foreign country
  or international organization of which the country is a member;
</p>
<p class="context">
  "National authority": the agency of the public administration responsible for
  safeguarding implementing and overseeing compliance with the LGPD throughout
  the national territory;
</p>
<p class="context">
  "Personal data": information related to an identified or identifiable natural
  person;
</p>
<p class="context">
  "Processing": any operation performed with personal data such as those
  referring to the collection production reception classification use access
  reproduction transmission distribution processing archiving storage deletion
  evaluation or control of information modification communication transfer
  dissemination or extraction; and
</p>
<p class="context">
  "Processor": a natural or legal person of public or private nature who
  processes personal data on behalf of the controller;
</p>
<p class="context">
  "Research entity": a public legal entity (or a division of it) or a private
  legal entity legally constituted under Brazilian laws with headquarters in
  Brazil which includes in its institutional mission or in its social or
  statutory scope basic or applied research of a historical scientific
  technological or statistical nature;
</p>
<p class="context">
  "Sensitive personal data": personal data concerning racial or ethnic origin
  religious belief political opinion membership in a trade union or religious
  philosophical or political organization data concerning health or sexual life
  genetic or biometric data when linked to a natural person.
</p>
<p class="title">3. MZIC PLATFORM DEFINITIONS</p>
<p class="context">
  The terms defined below may be used in the singular or plural without
  affecting their definition:
</p>
<p class="context">
  "AML" means Anti-Money Laundering. In the context of the MZIC Platform it
  refers to the set of procedures laws or regulations aimed at preventing the
  practice of generating income through illegal actions and making such
  illicitly obtained resources appear legal.
</p>
<p class="context">
  "Artist" is the original holder of the copyrights of a Content.
</p>
<p class="context">
  "Audiovisual Work" means the work resulting from the fixation of images with
  or without sound intended to create through its reproduction the impression of
  movement regardless of the processes of its capture the initial or subsequent
  support used to fix it as well as the means used for its dissemination.
</p>
<p class="context">
  "Blockchain" means a decentralized and distributed digital ledger that records
  transactions across multiple computers in such a way that the recorded
  transactions cannot be retroactively altered. This ensures that all
  transactions are transparent secure and permanently recorded.
</p>
<p class="context">
  "Content" means any type of Musical Work Sound Recording or Audiovisual Work
  subject to protection under Copyright Law that is the subject of a transaction
  on MZIC Platform.
</p>
<p class="context">
  "Copyright Law" and "LDA" refer to Brazilian Federal Law 9.610 of February 19
  1998.
</p>
<p class="context">
  "Cryptocurrency" means a digital or virtual form of currency that uses
  cryptography for security. It operates independently of a central bank and is
  primarily based on Blockchain technology.
</p>
<p class="context">
  "Dapp" means Decentralized Application. Unlike traditional applications that
  run on a centralized server Dapps run on a network of Blockchains that is not
  controlled by any authority.
</p>
<p class="context">
  "DSP" means Digital Service Provider which are third-party digital platforms
  that make Content available for commercial exploitation by end consumers such
  as Spotify Deezer Apple Music Amazon YouTube among others.
</p>
<p class="context">
  "ERC-721" stands for a standard for representing the ownership of non-fungible
  tokens or unique items on blockchains compatible with the Ethereum Virtual
  Machine (EVM).
</p>
<p class="context">
  "Floor Price" means the current market value of a Fractional Token.
</p>
<p class="context">
  "Fiduciary Currency" means a currency issued by the government that is not
  backed by a physical asset such as gold or silver but by the trust of the
  people.
</p>
<p class="context">
  "Fractional Tokens" refer to ERC-721 Tokens that represent a participation in
  the ownership of Content. By acquiring Fractional Tokens the User becomes the
  owner of a proportional fraction of the copyrights associated with that
  Content. This may include the right to a portion of the Royalties generated by
  the commercial use of the Content proportional to the fraction of rights
  acquired.
</p>
<p class="context">
  "KYC" stands for Know Your Customer. In MZIC Platform it refers to the process
  of identifying and verifying the identity of its Users.
</p>
<p class="context">
  "Minting" means the process of creating a new digital asset such as a token or
  coin on a Blockchain.
</p>
<p class="context">
  "Music Tokens" refer to ERC-721 Tokens used to exclusively represent the
  copyrights of an original Content of an Artist. These tokens do not have
  intrinsic financial value but serve as a digital representation of the
  copyright of an original Content of an Artist.
</p>
<p class="context">
  "Musical Work" means a musical composition whether with lyrics or not.
</p>
<p class="context">
  "MZIC Platform" means the set of proprietary applications of MZIC LLC whether
  linked to third-party applications or not which are made available to Users.
</p>
<p class="context">
  "Polygon" means an open-source Blockchain-based platform that supports Smart
  Contracts. It provides the infrastructure for creating decentralized
  applications and tokens.
</p>
<p class="context">
  "Power of Attorney" refers to the authority that MZIC Platform holds on behalf
  of Rights Holders. MZIC Platform uses this authority to collect and distribute
  Royalties protect against rights infringements and remove Content from
  unauthorized platforms.
</p>
<p class="context">
  "Rights Holder" refers to individuals or legal entities that hold certain
  copyrights over the Content. This includes Artists as original rights holders
  and Users who acquire Fractional Tokens as holders by transfer of rights. When
  Fractional Tokens of a Content are transacted on MZIC platform the acquiring
  Users also become Rights Holders over the portion of the copyright or related
  rights represented by their acquired fractions through the transfer of rights.
</p>
<p class="context">
  "Royalties" mean payments made to co-owners of a Content for its commercial
  use. Royalties are collected and distributed to Fractional Token owners. By
  acquiring Fractional Tokens Users may become Rights Holders and may have the
  right to receive Royalties.
</p>
<p class="context">
  "Smart Contracts" mean self-executing contracts with the terms of the
  agreement directly written into code lines. They automatically execute
  transactions and store information when specific conditions are met.
</p>
<p class="context">
  "Smart Digital Account" is an account on the Polygon Blockchain represented by
  a smart contract. This account is linked to the user's email and password on
  MZIC Platform and the users' tokens are efficiently and securely managed by
  the linked smart contract while MZIC Platform acts as a facilitator ensuring
  the execution of transactions within its marketplace.
</p>
<p class="context">
  "Smart Digital Account Address" is a unique identifier associated with the
  Smart Digital Account representing a destination for payments and transactions
  on the Blockchain.
</p>
<p class="context">
  "Sound Recording" means any fixation of sounds of a performance or
  interpretation or other sounds or a representation of sounds that is not a
  fixation included in an Audiovisual Work.
</p>
<p class="context">
  "Synchronization" means the fixation of Content in an Audiovisual Work.
</p>
<p class="context">
  "Token" means a digital asset created on a Blockchain. It can represent a
  variety of digital and physical assets and can be used for a multitude of
  purposes including as a unit of value a representation of ownership or access
  rights.
</p>
<p class="context">
  "User" means any natural or legal person who registers for an account on MZIC
  Platform.
</p>
<p class="context">
  "User Content" means all materials and data that MZIC Platform allows the User
  to upload submit store send or receive through the MZIC Platform. The User
  retains ownership of any intellectual property rights the User holds in such
  User Content.
</p>
<p class="title">4. RESPONSIBLE PARTIES FOR DATA PROCESSING</p>
<p class="context">
  For the services offered by MZIC Platform all decisions regarding the
  processing of personal data of its Users are made by the Company as well as
  the effective processing of the data itself.
</p>
<p class="title">5. DATA COLLECTION SOURCES</p>
<p class="context">
  MZIC Platform collects the following data from its users through the following
  sources:
</p>
<p class="context">
  - Electronic Pages of MZIC Platform: All official websites and portals of MZIC
  Platform can be used for the collection of personal data. This includes both
  the website operated directly by the Company through the Company's domains and
  IP addresses as well as websites or pages established by the Company on
  third-party services such as Facebook Instagram LinkedIn and other third
  parties that offer such services;
</p>
<p class="context">
  - Electronic Mail and Instant Messaging Systems: Services used to maintain
  electronic communications between the User and MZIC Platform including
  services provided directly by the Company or third-party services such as
  WhatsApp SMS (short message service) and similar;
</p>
<p class="context">
  - Mobile Applications of MZIC Platform: Mobile applications provided directly
  by the Company or through third-party services such as Google Apple and
  others.
</p>
<p class="context">
  - Customer Service Center Contact Center and Commercial Areas: Communications
  made with the User or by the User through the Company's customer service and
  relationship centers;
</p>
<p class="context">
  - Advertisements Promotions and Online Forms: Interactions with any type of
  advertisements promotions and online forms of MZIC Platform or strategic
  partners;
</p>
<p class="context">
  - Offline Records: Records filled out offline distributed during events and
  other interactions with MZIC Platform and its partners;
</p>
<p class="context">
  - Data Received from Third Parties: Including social networks and third-party
  websites such as Facebook Instagram LinkedIn and similar data aggregator
  services MZIC Platform partners and public sources.
</p>
<p class="title">6. DATA COLLECTED PROCESSED AND LEGAL BASIS</p>
<p class="context">
  The Company may collect the following data from its Users during their
  interaction with MZIC Platform through the data collection sources mentioned
  above:
</p>
<p class="context">
  (1) Data collected when the User creates an account on MZIC Platform:
</p>
<p class="context">
  - User Data: When a User registers for an account on MZIC Platform the Company
  collects basic identification data and contact information. The type of data
  collected and processed depends on the type of services the User has access to
  on MZIC Platform. It also depends on the type of account created by the User
  the country in which the User is located and whether the User uses third-party
  services to access their account. This may include the following data and User
  information:
</p>
<p class="context">Legal name;</p>
<p class="context">Email address;</p>
<p class="context">Phone number;</p>
<p class="context">Password;</p>
<p class="context">User profile name.</p>
<p class="context">
  - User’s Physical Address Data: The Company may request and process the User's
  physical address data for the following reasons:
</p>
<p class="context">
  To check the User's eligibility for a service option on MZIC Platform;
</p>
<p class="context">To send communications required by law;</p>
<p class="context">To provide assistance and support options to the User;</p>
<p class="context">For financial billing purposes.</p>
<p class="context">
  In some cases the Company may use third-party applications such as Google's
  Places API to assist the User in verifying their address.
</p>
<p class="context">
  Legal basis for data collection and processing: Compliance with legal or
  regulatory obligations fulfillment of contractual obligations and legitimate
  interest of the Controller or third parties.
</p>
<p class="context">
  (2) Data collected when the User uses MZIC Platform Services:
</p>
<p class="context">
  This information relates to the data and personal information collected and
  processed when the User accesses and/or uses the services of MZIC Platform
  listed in the following non-exhaustive sections forming the category of Usage
  Data.
</p>
<p class="context">
  (a) Information about how the User uses MZIC Platform services:
</p>
<p class="context">
  - Information about the User's choices for MZIC Platform services;
</p>
<p class="context">
  - Information about the User's interaction with MZIC Platform services
  including date and time such as: (i) Fractional Tokens acquired or disposed of
  by the User (ii) search results (iii) streaming history (iv) preferences and
  settings (v) interactions with other Users of MZIC Platform services; (vi)
  browsing history;
</p>
<p class="context">
  - Company's understanding of the User's interests and preferences based on the
  use of MZIC Platform services;
</p>
<p class="context">- User Content.</p>
<p class="context">(b) Technical Data:</p>
<p class="context">- URL information;</p>
<p class="context">- Online identifiers such as cookie data and IP address;</p>
<p class="context">
  - Information about the devices used by the User such as: (i) device ID (ii)
  network connection type [Wifi 4G 5G LTE Bluetooth etc.] (iii) access provider
  (iv) network and device performance (v) browser type (vi) language (vii)
  operating system (viii) MZIC app version.
</p>
<p class="context">
  (c) Cookie Policy: Cookies are files saved on the User's phone tablet or
  computer when visiting a website. For information on how the Company uses
  cookies and how the User can manage their cookie policy see the Cookie Policy
  in section 14 below.
</p>
<p class="context">
  (d) General Location (non-precise): The User's general location includes the
  country region or state. The Company may have access to this information
  through technical data such as the User's IP address or device language
  setting. This helps the Company to: (i) meet geographical requirements under
  the Company's contracts with copyright holders and (ii) deliver content and
  services that are relevant to the User.
</p>
<p class="context">
  (e) Transaction Data: This includes details about Fractional Tokens acquired
  or disposed of by the User as well as Royalties received by the User. These
  data allow the Company to manage transactions calculate Royalties and provide
  the User with relevant services.
</p>
<p class="context">
  (f) Device sensor data: These are data from the User's device sensor generated
  by motion or orientation eventually necessary to provide service
  functionalities that require such data. These data are collected by the User's
  devices based on how the User holds or moves them.
</p>
<p class="context">
  Legal basis for data collection and processing: Compliance with legal or
  regulatory obligations fulfillment of contractual obligations and legitimate
  interest of the Controller or third parties.
</p>
<p class="context">(3) Additional data that the User may choose to provide:</p>
<p class="context">
  (a) Payment Method Data: This includes the personal data that the User
  provides when adding a payment method to their MZIC Platform account such as:
  (i) civil name (ii) type of payment method [credit card debit card etc.] (iii)
  if the User adds a credit or debit card the type or brand of the card card
  number expiration date and security code and (iv) billing address associated
  with the card [ZIP code User's full address city state country etc.]
</p>
<p class="context">
  Note: For security reasons the Company never stores complete payment method
  information but only what is necessary to process payments.
</p>
<p class="context">
  (b) Transaction Data: This includes the User's personal data processed when
  the User initiates an acquisition of Fractional Tokens on MZIC Platform. This
  information allows for the efficient processing of transactions made on MZIC
  Platform such as: (i) details associated with payment methods (ii) date and
  time of the transaction and (iii) transaction amount details.
</p>
<p class="context">
  (c) Identity Verification Data: This includes User's personal data processed
  when the User initiates a disposition of Fractional Tokens or the collection
  of Royalties on MZIC Platform. This information is necessary to comply with
  AML legislation and protect other Users from fraudulent activities. The User's
  personal data collected and processed include: (i) CPF (or similar depending
  on the User's country of location) (ii) date of birth (iii) full address (iv)
  identity documents [identity card driver's license passport etc.].
  Specifically for Rights Holders as the KYC verification process is mandatory
  for accessing certain MZIC Platform services the following additional
  information will be collected and processed: (i) country of location (ii)
  mobile phone number (iii) photo of the identity document and (iv) photo of the
  User themselves (selfie).
</p>
<p class="context">
  Note: All data mentioned in this section (c) is collected by the Company's
  third-party service called Plaid specialized in secure financial services. For
  more details on how Plaid collects and processes Users' personal data please
  read Plaid's privacy policy at
  https://plaid.com/legal/#end-user-privacy-policy.
</p>
<p class="context">
  (d) Research Data: This includes the data that the User provides to the
  Company when participating in a survey which is collected and processed by the
  Company.
</p>
<p class="context">
  Legal basis for data collection and processing: Compliance with legal or
  regulatory obligations fulfillment of contractual obligations legitimate
  interest of the Controller or third parties studies by research organizations.
</p>
<p class="title">7. PROCESSING FOR OTHER PURPOSES</p>
<p class="context">
  In the current digital landscape data plays a crucial role in enhancing User
  experience improving security and ensuring legal compliance. MZIC Platform
  collects and processes personal data to personalize interactions maintain a
  secure platform fulfill legal obligations research and develop better features
  and provide relevant marketing and advertising content.
</p>
<p class="context">
  Aligned with our commitment to transparency this section aims to provide clear
  and comprehensive information about our purposes for using Users' personal
  data and the specific data processing activities related to each purpose.
</p>
<p class="context">
  (a) Providing Personalizing and Improving MZIC Platform Services
</p>
<p class="context">
  MZIC Platform uses Users' personal data to enhance service delivery. This
  includes setting up the account personalizing the experience providing
  personalized recommendations and improving the features and User experience of
  MZIC Platform. The goal is to optimize interactions and meet the individual
  needs of each User.
</p>
<p class="context">
  Purpose of processing: Providing Personalizing and Improving MZIC Platform
  Services.
</p>
<p class="context">
  Legal basis: Fulfillment of contractual obligations and legitimate interest of
  the Controller or third parties.
</p>
<p class="context">
  Categories of data processed: User Data Usage Data Payment Method Data User's
  Physical Address Data.
</p>
<p class="context">(b) Enabling Social Sharing</p>
<p class="context">
  MZIC Platform uses Users' personal data to enable features that allow them to
  share content with others. This enhances the User experience by promoting
  social connections and allows the User to engage more deeply with the content.
</p>
<p class="context">Purpose of processing: Enabling Social Sharing.</p>
<p class="context">
  Legal basis: Fulfillment of contractual obligations and legitimate interest of
  the Controller or third parties.
</p>
<p class="context">Categories of data processed: User Data Usage Data.</p>
<p class="context">(c) Transaction Processing</p>
<p class="context">
  User's personal data is essential for transaction processing on MZIC Platform.
  This includes verifying the User's payment details executing financial
  transactions related to the acquisition of Fractional Tokens and maintaining a
  record of all User transactions for future reference and customer support
  services.
</p>
<p class="context">Purpose of processing: Transaction Processing.</p>
<p class="context">
  Legal basis: Fulfillment of contractual obligations and legitimate interest of
  the Controller or third parties.
</p>
<p class="context">
  Categories of data processed: User Data Transaction Data Payment Method Data
  User's Physical Address Data.
</p>
<p class="context">(d) Security and Protection</p>
<p class="context">
  MZIC Platform processes User's personal data to ensure the security of the
  platform. This includes measures such as verifying the User's identity
  detecting and preventing fraud ensuring transaction security and protecting
  the interests of the Users.
</p>
<p class="context">Purpose of processing: Security and Protection.</p>
<p class="context">
  Legal basis: Fulfillment of legal or regulatory obligations fulfillment of
  contractual obligations and legitimate interest of the Controller or third
  parties.
</p>
<p class="context">
  Categories of data processed: User Data Usage Data Transaction Data Payment
  Method Data User's Physical Address Data Identity Verification Data.
</p>
<p class="context">(e) Legal and Contractual Obligations with Third Parties</p>
<p class="context">
  MZIC Platform processes User's personal data to fulfill legal and contractual
  obligations. This includes responsibilities towards third parties such as
  Rights Holders and activities related to the execution and enforcement of
  Copyright ownership contracts. These activities may involve transaction
  processing managing Royalty payments and sharing relevant data as per
  contractual obligations or required by law.
</p>
<p class="context">
  Purpose of processing: Legal and Contractual Obligations with Third Parties.
</p>
<p class="context">
  Legal basis: Fulfillment of legal or regulatory obligations fulfillment of
  contractual obligations and legitimate interest of the Controller or third
  parties.
</p>
<p class="context">
  Categories of data processed: User Data Transaction Data Payment Method Data
  User's Physical Address Data Identity Verification Data.
</p>
<p class="context">(f) Action against Copyright Infringement</p>
<p class="context">
  MZIC Platform processes User's personal data to detect and prevent Copyright
  infringements. This includes identifying and taking appropriate actions
  against unauthorized use or sharing of Copyright-protected Content.
</p>
<p class="context">
  Purpose of processing: Action against Copyright Infringement.
</p>
<p class="context">
  Legal basis: Fulfillment of legal or regulatory obligations fulfillment of
  contractual obligations and legitimate interest of the Controller or third
  parties.
</p>
<p class="context">Categories of data processed: User Data Usage Data.</p>
<p class="context">(g) Legal Claims</p>
<p class="context">
  User's personal data may be processed to establish exercise or defend legal
  claims. This is essential to protect the rights of the Company its property or
  safety as well as the rights property or safety of Users or third parties.
</p>
<p class="context">Purpose of processing: Legal Claims.</p>
<p class="context">
  Legal basis: Fulfillment of legal or regulatory obligations fulfillment of
  contractual obligations and legitimate interest of the Controller or third
  parties.
</p>
<p class="context">
  Categories of data processed: User Data Usage Data Transaction Data User's
  Physical Address Data Identity Verification Data.
</p>
<p class="context">
  (h) Troubleshooting and Improvement of MZIC Platform Service
</p>
<p class="context">
  User's personal data is essential for diagnosing troubleshooting and
  correcting any issues related to MZIC Platform services. This allows
  maintaining the performance functionality and reliability of MZIC Platform.
</p>
<p class="context">
  Purpose of processing: Troubleshooting and Improvement of MZIC Platform
  Service.
</p>
<p class="context">
  Legal basis: Legitimate interest of the Controller or third parties.
</p>
<p class="context">
  Categories of data processed: User Data Usage Data Transaction Data.
</p>
<p class="context">(i) Research and Development</p>
<p class="context">
  MZIC Platform processes User's personal data to inform research and
  development initiatives of MZIC Platform. This allows understanding User
  behavior improving MZIC Platform services and innovating new features to
  enhance the User experience.
</p>
<p class="context">Purpose of processing: Research and Development.</p>
<p class="context">
  Legal basis: Legitimate interest of the Controller or third parties.
</p>
<p class="context">
  Categories of data processed: Usage Data Transaction Data.
</p>
<p class="context">(j) Marketing and Advertising</p>
<p class="context">
  User's personal data helps develop deliver and track the effectiveness of
  marketing and advertising campaigns related to MZIC Platform. This includes
  personalizing content to match User preferences and interests.
</p>
<p class="context">Purpose of processing: Marketing and Advertising.</p>
<p class="context">
  Legal basis: Legitimate interest of the Controller or third parties.
</p>
<p class="context">
  Categories of data processed: User Data Usage Data Transaction Data.
</p>
<p class="title">8. USER’S RIGHTS</p>
<p class="context">
  The rights of the data subject as provided by LGPD include:
</p>
<p class="context">
  - Right to confirmation and access: The data subject has the right to obtain
  from the service confirmation as to whether their personal data is being
  processed and if so the right to access their personal data.
</p>
<p class="context">
  - Right to rectification: The data subject has the right to request the
  correction of incomplete inaccurate or outdated data.
</p>
<p class="context">
  - Right to limitation of data processing: The data subject has the right to
  limit the processing of their personal data being able to demand the deletion
  of unnecessary excessive or improperly processed data in accordance with LGPD.
</p>
<p class="context">
  - Right to object: The data subject has the right to object at any time to the
  processing of their data for reasons related to their particular situation
  based on one of the exceptions to consent or in case of non-compliance with
  LGPD.
</p>
<p class="context">
  - Right to data portability: The data subject has the right to data
  portability to another service or product provider upon express request in
  accordance with the regulations of the national authority subject to trade and
  industrial secrets.
</p>
<p class="context">
  - Right not to be subject to automated decisions: The data subject has the
  right to request the review of decisions made solely based on automated
  processing of personal data that affect their interests including decisions
  aimed at defining their personal professional consumption and credit profile
  or aspects of their personality.
</p>
<p class="title">9. DATA SHARING</p>
<p class="context">
  The Company informs that it will share the user data collected on MZIC
  Platform with third parties. To maintain transparency between users and the
  Company data sharing will fully comply with the provisions of MZIC Platform's
  Privacy Policy and will primarily be done with companies affiliated with the
  Company or its economic group. User data may also be shared in the following
  ways:
</p>
<p class="title">Publicly Available Information</p>
<p class="context">
  A crucial aspect of the operation of MZIC Platform is the availability of
  certain personal data for public visibility which is essential for creating a
  sense of community and enhancing the user experience. This transparency allows
  the user to understand the dynamics of MZIC Platform make informed decisions
  and fully engage with what MZIC Platform offers.
</p>
<p class="context">
  In the spirit of total transparency the following personal data of the user
  will always be available for public visibility within the MZIC Platform:
</p>
<p class="context">Profile name</p>
<p class="context">Profile picture</p>
<p class="context">Profile background picture</p>
<p class="context">Smart Digital Account address</p>
<p class="context">Current inventory of Fractional Tokens</p>
<p class="context">
  Details such as the Smart Contract address on the Blockchain and other
  Blockchain-related information.
</p>
<p class="context">
  The User may share certain information on third-party services such as social
  networks or instant messaging platforms. This includes the following User
  data:
</p>
<p class="context">Profile name</p>
<p class="context">Profile URL</p>
<p class="context">User-generated content and details about it</p>
<p class="context">Smart Digital Account address</p>
<p class="context">
  When this sharing occurs third-party services may potentially retain a copy of
  this information to enable functionalities. Once personal data is shared or
  retained by a third-party service the control of this data is governed by the
  privacy policies of each third-party service.
</p>
<p class="title">Third-Party Services</p>
<p class="context">
  MZIC Platform sometimes shares User data with third-party services so that
  they can assist MZIC Platform in providing improving protecting and promoting
  MZIC Platform.
</p>
<p class="context">
  For example MZIC Platform shares payment method data and user address data
  with our payment processing provider to handle financial transactions. These
  third-party services are subject to the rules and laws of their respective
  locations and have strict data processing policies to ensure the security and
  privacy of User data.
</p>
<p class="context">Third-party service: Payment processing.</p>
<p class="context">
  Categories of shared data: User data Payment method data Transaction data User
  address data Identity verification data.
</p>
<p class="context">Purpose of sharing: User payment processing.</p>
<p class="title">Corporate Transfers</p>
<p class="context">
  In the event of corporate restructuring merger acquisition or sale of MZIC
  Platform the personal data of the user collected by MZIC Platform may be among
  the transferred assets. Since MZIC Platform does not store sensitive personal
  data this data will not be transferred in these occurrences.
</p>
<p class="title">10. STORAGE PERIOD OF USER PERSONAL DATA</p>
<p class="context">
  The Company believes in retaining User personal data only for the time
  necessary to fulfill the purposes for which they were collected including to
  satisfy any legal accounting or reporting requirements. User personal data is
  kept only for the time necessary to provide the services of MZIC Platform and
  for the legitimate and essential business purposes of the MZIC Platform such
  as:
</p>
<p class="context">
  Maintenance of the performance of the MZIC Platform services
</p>
<p class="context">Making data-driven business decisions</p>
<p class="context">Compliance with legal obligations</p>
<p class="context">Resolution of third-party disputes</p>
<p class="context">(a) Data Retention</p>
<p class="context">
  User personal data is a matter of trust and respect for the Company and it is
  retained only for as long as necessary remaining secure until the User decides
  to close their account on MZIC Platform. At the moment the User chooses to do
  so the Company treats User's decision with great responsibility. The Company
  immediately discards certain information such as payment method data that is
  no longer necessary ensuring that the User's personal data leaves MZIC
  Platform systems when it no longer serves any purpose for MZIC Platform
  services.
</p>
<p class="context">
  For inactive accounts MZIC Platform adopts a careful approach rather than an
  automated deletion process. This is because by the nature of MZIC Platform
  services these accounts may possess assets or financial balances with MZIC
  Platform. Preserving the data associated with these accounts helps protect
  User's interests and ensures that their assets or financial balances are
  properly managed.
</p>
<p class="context">(b) After Closure of the Account on MZIC Platform</p>
<p class="context">
  Even after the account closure MZIC Platform continues to store some of User's
  data to defend against potential legal investigations or to comply with
  regulatory requirements such as Anti-Money Laundering (AML) regulations tax
  collection and others. The data is strictly maintained for these legal
  purposes and is not used for regular operations.
</p>
<p class="context">(c) Legal and Business Needs</p>
<p class="context">
  The Company may retain User's personal data for a longer period in case of a
  complaint or if the Company reasonably believes there is a prospect of
  litigation regarding the Company's relationship with the User. For example we
  may retain relevant data for ongoing investigations or disputes for the
  necessary period until resolution.
</p>
<p class="context">(d) Anonymization and Data Aggregation</p>
<p class="context">
  Company may anonymize and/or aggregate personal data and use these anonymized
  or aggregated data for Company’s internal business and marketing strategies.
  This use does not identify the User individually and helps Company improve its
  services and User experiences.
</p>
<p class="context">(e) User Control over Data</p>
<p class="context">
  The User has the right to request deletion of their data under certain
  circumstances. However the User should note that deleting certain data may
  affect the ability to use the services of MZIC Platform since only absolutely
  necessary data for the operation of MZIC Platform is collected and processed.
  The User can make this request through the Settings center in the MZIC
  Platform mobile app or by sending an email to the legal department at
  legal&#64;mzic.io.
</p>
<p class="context">(f) Data Breach Notification</p>
<p class="context">
  A data breach refers to an incident in which unauthorized individuals gain
  access to MZIC Platform and access personal data. Breaches can result from
  both external malicious actions such as cyberattacks and internal system
  vulnerabilities or human errors.
</p>
<p class="context">(g) Response to a Data Breach</p>
<p class="context">
  Upon identifying a potential data breach an immediate investigation is
  initiated. MZIC Platform response team will assess the extent of the breach
  identify the affected data and take necessary measures to contain and mitigate
  the breach.
</p>
<p class="context">
  If it is determined that a data breach may have compromised the User's
  personal data the User will be notified as soon as possible. This notification
  will contain information about the nature of the breach the type of data that
  was potentially compromised the measures taken to address the breach and what
  the User can do to further protect themselves.
</p>
<p class="context">
  The contact details provided by the User will be used to communicate about the
  data breach. However if it is not possible to contact the User through these
  channels or if it is necessary to notify a large number of Users at once
  additional communication methods may be used such as public notices on MZIC
  Platform website or announcements through our services on MZIC Platform.
</p>
<p class="context">
  Any data breach may also be reported to relevant authorities as required by
  law and the Company will fully cooperate with them in their investigations.
</p>
<p class="context">
  After any data breach a comprehensive review will be conducted to understand
  how the breach occurred and how the response was handled. This information
  will be used to improve security measures and prevent similar incidents in the
  future.
</p>
<p class="context">(h) Data Archiving and Backup</p>
<p class="context">
  The Company archives data to maintain a long-term record of User activity on
  MZIC Platform. Archiving helps the Company maintain a robust and
  chronologically organized database that can support legal and regulatory
  compliance historical analysis and internal audits.
</p>
<p class="context">
  In addition to archiving regular backups of the databases related to MZIC
  Platform are performed. These backups create duplicates of the data at
  predetermined intervals protecting against data loss in case of technical
  issues system failures or unforeseen circumstances.
</p>
<p class="context">
  All files and backups of MZIC Platform are stored in secure data centers with
  strict access controls. These centers employ advanced physical and virtual
  security measures to protect the data against unauthorized access corruption
  or destruction.
</p>
<p class="context">(i) Data Restoration</p>
<p class="context">
  In the event of data loss there are procedures for the quick and complete
  restoration of data from the backups related to MZIC Platform. However while
  the goal is comprehensive recovery it is not possible to guarantee the
  restoration of every piece of data in all circumstances.
</p>
<p class="context">
  The User should note that despite the rigorous archiving and data backup
  procedures related to MZIC Platform it is not possible to completely eliminate
  the risk of data loss. The Company encourages Users to maintain personal
  backups of their most important data.
</p>
<p class="title">11. PERSONAL DATA SECURITY</p>
<p class="context">
  The security of Users' personal data is a fundamental priority for the
  Company. Several measures are implemented to ensure the protection of this
  data in systems related to MZIC Platform. These measures include
  pseudonymization encryption access policies and retention policies.
</p>
<p class="context">
  To ensure account security Users are advised to follow some best practices:
</p>
<p class="context">
  Use a unique and strong password exclusively for MZIC Platform account.
</p>
<p class="context">Never share the password with anyone.</p>
<p class="context">
  Limit access to the computer and browser especially on shared devices.
</p>
<p class="context">
  Log out after each session on MZIC Platform especially on shared devices.
</p>
<p class="context">
  Immediately contact MZIC Platform support upon noticing any suspicious
  activity.
</p>
<p class="context">
  Additionally a "Log out of all sessions" feature is provided to further
  strengthen the security of the User's account on MZIC Platform.
</p>
<p class="context">
  It is important for the user to remember that it is their responsibility to
  manage access to their account on MZIC Platform. These responsibilities are
  clearly outlined in the Terms of Service of MZIC Platform available at
  https://mzic.io/tos.
</p>
<p class="context">
  If other individuals gain access to the User's account on MZIC Platform they
  may access personal data controls and assets available in the account. It is
  the User's responsibility to allow only authorized individuals to use their
  account and the user should feel comfortable sharing their personal data with
  them. Additionally measures to protect the user's account on MZIC Platform can
  only be taken upon receiving a direct request from the user's account email.
</p>
<p class="context">
  The Company continuously works to enhance security measures and always provide
  a secure and protected MZIC Platform.
</p>
<p class="title">12. CHILDREN AND ADOLESCENTS' DATA</p>
<p class="context">(a) Minimum Age Limit</p>
<p class="context">
  The services of MZIC Platform are not designed or intended for individuals
  under the age of 18. This age limit is in line with the definition of
  adulthood in many jurisdictions and reflects the Company's commitment to
  protecting minors from potential risks.
</p>
<p class="context">(b) Prevention of Collection</p>
<p class="context">
  Company does not knowingly collect or process personal data from individuals
  under the age of 18. If a person is under the age of 18 please do not use MZIC
  Platform and refrain from providing any personal data.
</p>
<p class="context">(c) Awareness and Parental Responsibility</p>
<p class="context">
  If a parent or guardian and become aware that their child who is under the age
  of 18 has provided personal data to MZIC Platform parent or guardian should
  contact MZIC Platform immediately. Parents and guardians are encouraged to
  monitor their children's online activities and guide them in the responsible
  use of digital platforms.
</p>
<p class="context">(d) Response to Inappropriate Collection</p>
<p class="context">
  If it is discovered that Company has inadvertently collected personal data
  from a minor Company will take appropriate measures to remove this data from
  the records of MZIC Platform. This may also require the deletion of the
  account associated with such data on MZIC Platform.
</p>
<p class="context">(e) Shared Accounts</p>
<p class="context">
  The Company understands that in some cases accounts may be shared among family
  members or friends. Users should be aware that sharing access to an account on
  MZIC Platform carries risks including potential exposure to inappropriate
  content or actions. If Users share their account with minors Users are
  responsible for supervising their activities on MZIC Platform.
</p>
<p class="context">
  If User has any questions or concerns about MZIC Platform's policy regarding
  children's data or need to report a suspected violation please contact MZIC
  Platform directly which is here to ensure the safety and privacy of all Users
  regardless of age.
</p>
<p class="title">13. THIRD-PARTY APPLICATIONS</p>
<p class="context">
  MZIC Platform receives personal data from holders/users of the following
  third-party applications and/or sources:
</p>
<p class="context">
  (a) Third-Party Applications Used by the User to Connect with MZIC Platform
</p>
<p class="context">
  If the User connects their account on MZIC Platform through a third-party
  application service or device MZIC Platform may collect and use information
  from them. In most cases MZIC Platform needs this information to provide
  services to the User and enable any integration.
</p>
<p class="context">
  These third-party applications services or devices may include: social media
  platforms and devices including audio (e.g. speakers or headphones)
  smartwatches smart TVs mobile phones and tablets automotive (e.g. CarPlay)
  gaming consoles. Data Categories: User Data Usage Data.
</p>
<p class="context">(b) Technical Service Partners</p>
<p class="context">
  The technical service partners of MZIC Platform provide data about the User's
  usage and interactions with the services of MZIC Platform. This helps improve
  functionality and enhance User's experience on MZIC Platform. This includes
  mapping IP addresses to non-precise location data (e.g. country or region city
  state). MZIC Platform also works with security service providers that help
  protect user accounts on MZIC Platform. Data Categories: User Data Usage Data.
</p>
<p class="context">(c) Payment Partners</p>
<p class="context">
  The payment partners of MZIC Platform share information about Users
  transactions. This ensures smooth financial operations and helps Company
  resolve any payment-related issues. The data collected may include transaction
  details (e.g. date time amount and payment method) billing address and User
  support inquiries related to transactions. Data Categories: Payment Method
  Data Transaction Data.
</p>
<p class="context">(d) Marketing and Advertising Partners</p>
<p class="context">
  MZIC Platform receives insights from its marketing partners about their
  advertising campaigns. These insights are partners' understandings of Users'
  interests and preferences. This helps MZIC Platform provide more relevant
  information to the User. Data Categories: Usage Data.
</p>
<p class="context">(e) Acquired Companies</p>
<p class="context">
  If the Company acquires other companies or undergoes a merger process MZIC
  Platform may receive personal data about the User from those companies. This
  is to enhance the services of MZIC Platform and the User experience. Data
  Categories: User Data Usage Data.
</p>
<p class="title">14. COOKIES</p>
<p class="context">(a) What are Cookies?</p>
<p class="context">
  Cookies are small data packets stored in text files on the User's device (such
  as a computer mobile phone or tablet) when the User visits certain web pages.
  These data files contain information about the User's browsing preferences
  settings and browsing history. This information allows MZIC Platform to
  recognize the User when interacting with or revisiting MZIC Platform's
  services and customize the browsing experience according to the User's
  preferences.
</p>
<p class="context">
  There are two types of cookies: session cookies and persistent cookies.
  Session cookies are temporary and expire when you close your web browser.
  Persistent cookies on the other hand remain in your browser for a specified
  period of time or until you choose to delete them.
</p>
<p class="context">
  The cookies used on MZIC Platform may be served directly by MZIC Platform
  known as "first-party cookies" or by third parties known as "third-party
  cookies." First-party cookies help MZIC Platform remember the User's settings
  and preferences such as language selection and location. MZIC Platform also
  integrates third-party analytics cookies such as Google Analytics to help MZIC
  Platform understand how the User is using MZIC Platform enabling continuous
  improvement of the User experience.
</p>
<p class="context">
  More general information about cookies can be found at
  https://www.youronlinechoices.eu.
</p>
<p class="context">(b) How does the MZIC Platform use cookies?</p>
<p class="context">
  Cookies serve various functions such as enabling the User to navigate between
  pages efficiently remembering their preferences ensuring the security of their
  data helping the User personalize their experience and overall improving their
  interaction with MZIC Platform's services.
</p>
<p class="context">Cookie Category: Essential</p>
<p class="context">
  How does MZIC Platform use these cookies? These cookies are vital for the
  proper functioning of MZIC Platform's services. They enable essential
  functionalities such as User login account management and maintaining the
  security of MZIC Platform. If these cookies are disabled it may affect the
  performance or functionality of MZIC Platform's services and some areas may
  become inaccessible.
</p>
<p class="context">Cookie Category: Security</p>
<p class="context">
  How does MZIC Platform use these cookies? MZIC Platform uses these cookies to
  ensure its security and the integrity of User data. They help MZIC Platform
  identify and mitigate potential security risks. The absence of these cookies
  may affect the security of MZIC Platform and expose it to certain threats.
</p>
<p class="context">Cookie Category: Preferences</p>
<p class="context">
  How does MZIC Platform use these cookies? MZIC Platform uses these cookies to
  remember the User's individual settings and preferences such as their chosen
  language and location when using MZIC Platform's services. These cookies help
  personalize the User's experience making their interactions more efficient and
  meaningful.
</p>
<p class="context">Cookie Category: Analytics</p>
<p class="context">
  How does MZIC Platform use these cookies? MZIC Platform uses these cookies to
  gain insights into how the User interacts with its services. This includes
  understanding the popularity of certain content determining if the User has
  engaged with specific features and identifying areas that need improvement.
  The data collected by these cookies helps improve MZIC Platform's services and
  provide a better User experience.
</p>
<p class="context">Cookie Category: Advertising</p>
<p class="context">
  How does MZIC Platform use these cookies? MZIC Platform and its trusted
  advertising partners use these cookies to display more relevant ads to the
  User. They help MZIC Platform track the effectiveness of its advertising
  campaigns both within MZIC Platform's services and on other websites and
  provide the User with a more personalized advertising experience.
</p>
<p class="context">(c) Managing User Cookie Preferences</p>
<p class="context">
  Users have the right to choose whether to accept cookies or not. However since
  cookies can be an important part of the operation of MZIC Platform's services
  if Users remove or reject cookies this may affect the availability and
  functionality of MZIC Platform's services.
</p>
<p class="context">
  Below are some options for controlling or limiting how cookies are used on
  MZIC Platform:
</p>
<p class="context">
  Users can generally manage settings to remove or reject cookies from the
  browser manually within their browser's settings. To manage these settings
  Users should follow the instructions provided by their browser.
</p>
<p class="context">
  To prevent User data from being used by Google Analytics Users can install the
  Google Analytics Opt-out Browser Add-on.
</p>
<p class="context">
  For information on how tMZIC Platform's advertising partners may allow Users
  to opt out of receiving ads based on their web browsing history visit
  http://optout.aboutads.info/
</p>
<p class="context">(d) Changes to this Cookie Policy</p>
<p class="context">
  The Company may update or change this Cookie Policy from time to time. If any
  updates or changes are made they will be made available on the website at
  https://mzic.io/legal and the effective date at the top of this Privacy Policy
  will also be updated.
</p>
<p class="context">
  The Company will also notify the User by sending an email to the address
  provided by the User at the time of registration or through other appropriate
  communication channels. The User's continued use of MZIC Platform's services
  after any of these changes constitutes acceptance of the new Cookie Policy. If
  the User does not agree to any of these terms they should not use or access
  (or continue to access) MZIC Platform's services.
</p>
<p class="title">15. INTERNATIONAL DATA TRANSFER</p>
<p class="context">
  Since the Company is based in the United States of America the data collected
  and processed will be stored on Amazon AWS servers (www.aws.amazon.com)
  according to the highest existing security standards as mentioned in section
  13 above. In addition to this fact international data transfer may occur
  between the Company's headquarters in the United States of America and its
  affiliated companies subsidiaries and/or branches in Brazil.
</p>
<p class="title">16. PRIVACY POLICY UPDATES</p>
<p class="context">
  This document containing the Privacy Policy of MZIC Platform was last modified
  on February 20 2024.
</p>
<p class="context">
  The Privacy Policy contained in this document may undergo changes and updates
  for improvement at any time with the Company retaining the full right to such
  changes and updates. Therefore the Company invites all users of MZIC Platform
  to regularly access the page containing its Privacy Policy in order to be
  aware of these changes and updates.
</p>
