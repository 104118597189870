<ng-container *transloco="let t">
  <header class="mzic-header">
    <div class="mzic-header__wrapper">
      <div
        class="mzic-header__logo"
        routerLink="/home"
        (click)="openMenu.set(false)">
        <img src="assets/icons/mzic-logo-name.svg" />
      </div>
      <div class="mzic-header__menu" [ngClass]="openMenu() ? 'open' : ''">
        <nav class="mzic-header__menu__navbar">
          <ul class="mzic-header__menu__navbar__list">
            <li
              [class.mzic-header__menu__navbar__item--focus]="
                documentActive === 'terms-of-use'
              "
              (click)="handleMenuClick()"
              routerLink="{{ languageActive }}/terms-of-use">
              {{ t('terms') }}
            </li>
            <li
              [class.mzic-header__menu__navbar__item--focus]="
                documentActive === 'privacy'
              "
              (click)="handleMenuClick()"
              routerLink="{{ languageActive }}/privacy">
              {{ t('privacy') }}
            </li>
          </ul>

          <ul class="mzic-header__menu__navbar__langs">
            <li>
              <button
                class="language-button"
                [class.language-button--active]="languageActive === 'en'"
                type="button"
                (click)="switchLanguage('en')">
                en
              </button>
            </li>
            <li class="arrow">
              <p [class.arrow--reverse]="languageActive === 'pt-br'">←</p>
            </li>
            <li>
              <button
                class="language-button"
                [class.language-button--active]="languageActive === 'pt-br'"
                type="button"
                (click)="switchLanguage('pt-br')">
                pt
              </button>
            </li>
          </ul>
        </nav>
        <div class="mzic-header__menu__icon" (click)="handleMenuClick()">
          <span class="mzic-header__menu__icon__hamburger"></span>
        </div>
      </div>
    </div>
  </header>
</ng-container>
