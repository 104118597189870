import { CommonModule } from '@angular/common';
import { Component, effect, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { LanguageService } from '@mzic/mzic-services';
import { PrivacyEnComponent } from '../../components/documents/privacy-en.component';
import { PrivacyPtBrComponent } from '../../components/documents/privacy-pt-br.component';
import { TermsEnComponent } from '../../components/documents/terms-en.component';
import { TermsPtBrComponent } from '../../components/documents/terms-pt-br.component';

@Component({
  selector: 'mzic-shell',
  standalone: true,
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
  imports: [
    CommonModule,
    TermsPtBrComponent,
    TermsEnComponent,
    PrivacyPtBrComponent,
    PrivacyEnComponent,
    TranslocoModule,
  ],
})
export class ShellComponent implements OnInit {
  isPortuguese = false;
  isTerms = true;

  constructor(
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private translocoService: TranslocoService,
    private router: Router,
  ) {
    effect(() => {
      const lang = this.languageService.languageState();
      const doc = this.activatedRoute.snapshot.params['document'];
      const currentUrl = this.router.url;

      if (lang !== '') {
        const path = doc ? `/${doc}` : currentUrl;
        this.router.navigate([`${lang}${path}`]);
      }
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const langState = this.languageService.languageState();

      // Coleta o idioma da URL
      const lang = this.activatedRoute.snapshot.params['lang'];

      // Verifica se estamos em português. Considera a URL do browser sempre
      this.isPortuguese = lang === 'pt-br' || langState === 'pt-br';

      // Muda o idioma do transloco
      this.translocoService.setActiveLang(this.isPortuguese ? 'pt-br' : 'en');

      // Decide o tipo de documento carregar no template
      this.isTerms = params.get('document') === 'terms-of-use' ? true : false;
    });
  }
}
