import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import {
  ApiResponse,
  GetReleaseV2DistributionReleaseIdStores,
  GetReleaseV2DistributionReleaseIdValidations,
  GetReleaseV2Find,
  GetReleaseV2FindParams,
  GetReleaseV2FindReleaseId,
  PostReleaseV2DistributionReleaseId,
  PostReleaseV2DistributionReleaseIdBody,
  PostReleaseV2ValidateReleaseId,
} from '@mzic/mzic-interfaces';
import { httpParams, LanguageEnum } from '@mzic/mzic-utils';
import { MzicArtistLocalService } from '../mzic-artist-local/mzic-artist-local.service';

interface StoreDeprecated extends ApiResponse {
  data: [
    {
      id: number;
      iconUrl: string;
      name: string;
      active: boolean;
      tierPriceSale: {
        lsTierPriceRelease: [
          {
            id: number;
            value: number;
            name: string;
          },
        ];
        lsTierPriceTrack: [
          {
            id: number;
            value: number;
            name: string;
          },
        ];
      };
      lsMonetizationPolicy: [
        {
          id: number;
          name: string;
        },
      ];
      dependencyStore: {
        id: number;
        tierPriceReleaseId: number;
        tierPriceTrackId: number;
        monetizationPolicyId: number;
      };
      lsToolTip: [
        {
          type: string;
          title: string;
          text: string;
        },
      ];
    },
  ];
}

@Injectable({
  providedIn: 'root',
})
export class ReleasesService {
  private apiUrl = environment.apiUrl;

  constructor(
    private readonly _http: HttpClient,
    private mzicArtistLocalService: MzicArtistLocalService,
  ) {}

  getReleaseV2Find(teamId: string, params?: GetReleaseV2FindParams) {
    if (params?.page) {
      params.page--;
    }
    const headers = new HttpHeaders().append('TEAM-ID', teamId);
    return this._http.get<GetReleaseV2Find>(
      `${this.apiUrl}/api/distribution/release/v2/find`,
      {
        headers,
        ...httpParams(params),
      },
    );
  }

  getReleaseV2FindReleaseId(releaseId: number) {
    return this._http.get<GetReleaseV2FindReleaseId>(
      `${this.apiUrl}/api/distribution/release/v2/find/${releaseId}`,
      {
        headers: new HttpHeaders({ 'TEAM-ID': this.getTeamId() }),
      },
    );
  }

  postReleaseV2ValidateReleaseId(
    releaseId: number,
    userSettingLanguage: LanguageEnum = LanguageEnum.ENGLISH,
    teamId: number,
  ) {
    let headers = new HttpHeaders();
    headers = headers.append('TEAM-ID', teamId.toString());

    if (userSettingLanguage !== LanguageEnum.ENGLISH) {
      headers = headers.append('Auto-Translate', 'true');
    }

    return this._http.post<PostReleaseV2ValidateReleaseId>(
      `${this.apiUrl}/api/distribution/release/v2/validate/${releaseId}`,
      {},
      { headers },
    );
  }

  getReleaseV2DistributionReleaseIdValidations(
    releaseId: number,
    userSettingLanguage: LanguageEnum = LanguageEnum.ENGLISH,
  ) {
    let headers = new HttpHeaders().append('TEAM-ID', this.getTeamId());
    if (userSettingLanguage !== LanguageEnum.ENGLISH) {
      headers = new HttpHeaders()
        .append('Auto-Translate', 'true')
        .append('TEAM-ID', this.getTeamId());
    }

    return this._http.get<GetReleaseV2DistributionReleaseIdValidations>(
      `${this.apiUrl}/api/distribution/release/v2/distribution/${releaseId}/validations`,
      { headers },
    );
  }

  postReleaseV2DistributionReleaseId(
    teamId: string,
    releaseId: number,
    body: PostReleaseV2DistributionReleaseIdBody,
  ) {
    const headers = new HttpHeaders().append('TEAM-ID', teamId);
    return this._http.post<PostReleaseV2DistributionReleaseId>(
      `${this.apiUrl}/api/distribution/release/v2/distribution/${releaseId}`,
      body,
      { headers },
    );
  }

  getReleaseV2DistributionReleaseIdStores(releaseId: number) {
    const headers = new HttpHeaders().append('TEAM-ID', this.getTeamId());
    return this._http.get<GetReleaseV2DistributionReleaseIdStores>(
      `${this.apiUrl}/api/distribution/release/v2/distribution/${releaseId}/stores?active=false`,
      { headers },
    );
  }

  // getReleaseV2DistributionReleaseIdStores(releaseId: number) {
  //   const data = {
  //     timestamp: '2024-07-14T15:22:42.647784211Z',
  //     status: 'success',
  //     data: [
  //       {
  //         store: {
  //           id: 37,
  //           name: 'Yandex',
  //           active: true,
  //         },
  //         status: 'NEVER_DISTRIBUTED',
  //         isLive: false,
  //         enqueuedOn: '2024-06-25',
  //         deliveredOn: null,
  //         urlInStore: null,
  //         notEligibleForDistribution: null,
  //       },
  //       {
  //         store: {
  //           id: 8,
  //           name: 'Google Play',
  //           active: true,
  //           tierPriceSale: {
  //             lsTierPriceRelease: [
  //               {
  //                 id: 75,
  //                 value: 0.69,
  //                 name: 'Single Back',
  //               },
  //               {
  //                 id: 74,
  //                 value: 0.99,
  //                 name: 'Single Mid',
  //               },
  //               {
  //                 id: 73,
  //                 value: 1.29,
  //                 name: 'Single Front',
  //               },
  //               {
  //                 id: 22,
  //                 value: 1.98,
  //                 name: 'Digital 45',
  //               },
  //               {
  //                 id: 24,
  //                 value: 3.96,
  //                 name: 'EP',
  //               },
  //               {
  //                 id: 26,
  //                 value: 4.95,
  //                 name: 'Mini Album One',
  //               },
  //               {
  //                 id: 28,
  //                 value: 5.94,
  //                 name: 'Budget One',
  //               },
  //               {
  //                 id: 30,
  //                 value: 6.93,
  //                 name: 'Budget Two',
  //               },
  //               {
  //                 id: 32,
  //                 value: 7.92,
  //                 name: 'Back',
  //               },
  //               {
  //                 id: 34,
  //                 value: 8.91,
  //                 name: 'Mid',
  //               },
  //               {
  //                 id: 36,
  //                 value: 9.9,
  //                 name: 'Mid/Front',
  //               },
  //               {
  //                 id: 38,
  //                 value: 11.88,
  //                 name: 'Front/Plus',
  //               },
  //               {
  //                 id: 40,
  //                 value: 14.85,
  //                 name: 'Deluxe Three',
  //               },
  //             ],
  //             lsTierPriceTrack: [
  //               {
  //                 id: 42,
  //                 value: 0.69,
  //                 name: 'Back',
  //               },
  //               {
  //                 id: 44,
  //                 value: 0.99,
  //                 name: 'Mid',
  //               },
  //               {
  //                 id: 46,
  //                 value: 1.29,
  //                 name: 'Front',
  //               },
  //             ],
  //           },
  //         },
  //         status: 'NEVER_DISTRIBUTED',
  //         isLive: false,
  //         enqueuedOn: null,
  //         deliveredOn: null,
  //         urlInStore: null,
  //         notEligibleForDistribution: null,
  //       },
  //       {
  //         store: {
  //           id: 299,
  //           name: 'Sirius',
  //           active: true,
  //         },
  //         status: 'NEVER_DISTRIBUTED',
  //         isLive: false,
  //         enqueuedOn: null,
  //         deliveredOn: null,
  //         urlInStore: null,
  //         notEligibleForDistribution: null,
  //       },
  //       {
  //         store: {
  //           id: 313,
  //           name: 'KDigital',
  //           active: true,
  //         },
  //         status: 'NEVER_DISTRIBUTED',
  //         isLive: false,
  //         enqueuedOn: null,
  //         deliveredOn: null,
  //         urlInStore: null,
  //         notEligibleForDistribution: null,
  //       },
  //       {
  //         store: {
  //           id: 323,
  //           iconUrl:
  //             'https://mzic.revelator.pro/pt-br/assets/services/snap.svg',
  //           name: 'Snap',
  //           active: true,
  //         },
  //         status: 'NEVER_DISTRIBUTED',
  //         isLive: false,
  //         enqueuedOn: null,
  //         deliveredOn: null,
  //         urlInStore: null,
  //         notEligibleForDistribution: null,
  //       },
  //       {
  //         store: {
  //           id: 324,
  //           iconUrl:
  //             'https://mzic.revelator.pro/pt-br/assets/services/peloton.svg',
  //           name: 'Peloton',
  //           active: true,
  //         },
  //         status: 'NEVER_DISTRIBUTED',
  //         isLive: false,
  //         enqueuedOn: null,
  //         deliveredOn: null,
  //         urlInStore: null,
  //         notEligibleForDistribution: null,
  //       },
  //       {
  //         store: {
  //           id: 450,
  //           iconUrl:
  //             'https://mzic.revelator.pro/pt-br/assets/services/lickd.svg',
  //           name: 'Lickd',
  //           active: true,
  //         },
  //         status: 'NEVER_DISTRIBUTED',
  //         isLive: false,
  //         enqueuedOn: null,
  //         deliveredOn: null,
  //         urlInStore: null,
  //         notEligibleForDistribution: null,
  //       },
  //       {
  //         deliveredOn: '2024-07-14T15:22:42.238Z',
  //         enqueuedOn: '2024-07-14T15:22:42.238Z',
  //         isLive: false,
  //         status: 'NEVER_DISTRIBUTED',
  //         store: {
  //           id: 1,
  //           iconUrl:
  //             'https://mzic.revelator.pro/pt-br/assets/services/itunes.svg',
  //           name: 'iTunes/Apple Music + Shazam',
  //           active: false,
  //           tierPriceSale: {
  //             lsTierPriceRelease: [
  //               {
  //                 id: 1,
  //                 value: 1.98,
  //                 name: 'Digital 45',
  //               },
  //               {
  //                 id: 2,
  //                 value: 2.97,
  //                 name: 'Mini EP',
  //               },
  //               {
  //                 id: 3,
  //                 value: 3.96,
  //                 name: 'EP',
  //               },
  //               {
  //                 id: 4,
  //                 value: 4.95,
  //                 name: 'Mini Album One',
  //               },
  //               {
  //                 id: 5,
  //                 value: 4.95,
  //                 name: 'Mini Album Two',
  //               },
  //               {
  //                 id: 6,
  //                 value: 5.94,
  //                 name: 'Budget One',
  //               },
  //               {
  //                 id: 7,
  //                 value: 6.93,
  //                 name: 'Budget Two',
  //               },
  //               {
  //                 id: 8,
  //                 value: 7.92,
  //                 name: 'Back',
  //               },
  //               {
  //                 id: 9,
  //                 value: 8.91,
  //                 name: 'Mid',
  //               },
  //               {
  //                 id: 10,
  //                 value: 9.9,
  //                 name: 'Mid/Front',
  //               },
  //               {
  //                 id: 11,
  //                 value: 10.89,
  //                 name: 'Front One',
  //               },
  //               {
  //                 id: 12,
  //                 value: 10.89,
  //                 name: 'Front Two',
  //               },
  //               {
  //                 id: 13,
  //                 value: 11.88,
  //                 name: 'Front/Plus',
  //               },
  //               {
  //                 id: 14,
  //                 value: 12.87,
  //                 name: 'Deluxe One',
  //               },
  //               {
  //                 id: 15,
  //                 value: 13.86,
  //                 name: 'Deluxe Two',
  //               },
  //               {
  //                 id: 16,
  //                 value: 14.85,
  //                 name: 'Deluxe Three',
  //               },
  //               {
  //                 id: 17,
  //                 value: 15.84,
  //                 name: 'Deluxe Four',
  //               },
  //             ],
  //             lsTierPriceTrack: [
  //               {
  //                 id: 18,
  //                 value: 0.69,
  //                 name: 'Back',
  //               },
  //               {
  //                 id: 19,
  //                 value: 0.99,
  //                 name: 'Mid',
  //               },
  //               {
  //                 id: 20,
  //                 value: 1.29,
  //                 name: 'Front',
  //               },
  //             ],
  //           },
  //         },
  //         urlInStore: '',
  //       },
  //       {
  //         deliveredOn: '2024-07-14T15:22:42.238Z',
  //         enqueuedOn: '2024-07-14T15:22:42.238Z',
  //         isLive: false,
  //         status: 'NEVER_DISTRIBUTED',
  //         store: {
  //           id: 13,
  //           iconUrl:
  //             'https://mzic.revelator.pro/pt-br/assets/services/youtube-music.svg',
  //           name: 'YouTube Music',
  //           active: false,
  //         },
  //         urlInStore: '',
  //       },
  //       {
  //         deliveredOn: '2024-07-14T15:22:42.238Z',
  //         enqueuedOn: '2024-07-14T15:22:42.238Z',
  //         isLive: false,
  //         status: 'NEVER_DISTRIBUTED',
  //         store: {
  //           id: 17,
  //           iconUrl:
  //             'https://mzic.revelator.pro/pt-br/assets/services/amazon.svg',
  //           name: 'Amazon',
  //           active: false,
  //           tierPriceSale: {
  //             lsTierPriceRelease: [
  //               {
  //                 id: 78,
  //                 value: 0.69,
  //                 name: 'Single Back',
  //               },
  //               {
  //                 id: 77,
  //                 value: 0.99,
  //                 name: 'Single Mid',
  //               },
  //               {
  //                 id: 76,
  //                 value: 1.29,
  //                 name: 'Single Front',
  //               },
  //               {
  //                 id: 21,
  //                 value: 1.98,
  //                 name: 'Digital 45',
  //               },
  //               {
  //                 id: 23,
  //                 value: 3.96,
  //                 name: 'EP',
  //               },
  //               {
  //                 id: 25,
  //                 value: 4.95,
  //                 name: 'Mini Album One',
  //               },
  //               {
  //                 id: 27,
  //                 value: 5.94,
  //                 name: 'Budget One',
  //               },
  //               {
  //                 id: 29,
  //                 value: 6.93,
  //                 name: 'Budget Two',
  //               },
  //               {
  //                 id: 31,
  //                 value: 7.92,
  //                 name: 'Back',
  //               },
  //               {
  //                 id: 33,
  //                 value: 8.91,
  //                 name: 'Mid',
  //               },
  //               {
  //                 id: 35,
  //                 value: 9.9,
  //                 name: 'Mid/Front',
  //               },
  //               {
  //                 id: 37,
  //                 value: 11.88,
  //                 name: 'Front/Plus',
  //               },
  //               {
  //                 id: 39,
  //                 value: 14.85,
  //                 name: 'Deluxe Three',
  //               },
  //             ],
  //             lsTierPriceTrack: [
  //               {
  //                 id: 41,
  //                 value: 0.69,
  //                 name: 'Back',
  //               },
  //               {
  //                 id: 43,
  //                 value: 0.99,
  //                 name: 'Mid',
  //               },
  //               {
  //                 id: 45,
  //                 value: 1.29,
  //                 name: 'Front',
  //               },
  //             ],
  //           },
  //         },
  //         urlInStore: '',
  //       },
  //       {
  //         deliveredOn: '2024-07-14T15:22:42.238Z',
  //         enqueuedOn: '2024-07-14T15:22:42.238Z',
  //         isLive: false,
  //         status: 'NEVER_DISTRIBUTED',
  //         store: {
  //           id: 307,
  //           iconUrl:
  //             'https://mzic.revelator.pro/pt-br/assets/services/youtube.svg',
  //           name: 'YouTube Content ID',
  //           active: false,
  //           lsMonetizationPolicy: [
  //             {
  //               id: 684,
  //               name: 'Monetize in all countries',
  //             },
  //             {
  //               id: 685,
  //               name: 'Track in all countries',
  //             },
  //           ],
  //           dependencyStore: {
  //             id: 13,
  //             name: 'Youtube Music',
  //           },
  //           lsToolTip: [
  //             {
  //               type: 'MONETIZATION_YOUTUBE_CONTENT_ID',
  //               title: 'Descrições de cada política:',
  //               text: "Quando a opção 'Monetizar em todos os países' estiver ativa, você recebera royalties de acordo com o uso/reprodução de suas faixas.\n\nQuando a opção 'Monitorar em todos os países' estiver ativa, você receberá estatísticas sobre o uso/reprodução de suas faixas.",
  //             },
  //           ],
  //         },
  //         urlInStore: '',
  //       },
  //       {
  //         deliveredOn: '2024-07-14T15:22:42.238Z',
  //         enqueuedOn: '2024-07-14T15:22:42.238Z',
  //         isLive: false,
  //         status: 'NEVER_DISTRIBUTED',
  //         store: {
  //           id: 309,
  //           iconUrl:
  //             'https://mzic.revelator.pro/pt-br/assets/services/facebook.svg',
  //           name: 'Facebook Audio Library',
  //           active: false,
  //         },
  //         urlInStore: '',
  //       },
  //       {
  //         deliveredOn: '2024-07-14T15:22:42.238Z',
  //         enqueuedOn: '2024-07-14T15:22:42.238Z',
  //         isLive: false,
  //         status: 'NEVER_DISTRIBUTED',
  //         store: {
  //           id: 310,
  //           iconUrl:
  //             'https://mzic.revelator.pro/pt-br/assets/services/facebook.svg',
  //           name: 'Facebook Rights Manager',
  //           active: false,
  //           lsMonetizationPolicy: [
  //             {
  //               id: 686,
  //               name: 'Claim Ad Earnings',
  //             },
  //             {
  //               id: 687,
  //               name: 'Monitor',
  //             },
  //           ],
  //           lsToolTip: [
  //             {
  //               type: 'MONETIZATION_FACEBOOk_RIGHTS_MANAGER',
  //               title: 'Descrições de cada política:',
  //               text: "Quando a opção 'Recolher ganhos de anúncios' estiver ativa, você recebera royalties de acordo com o uso/reprodução de suas faixas.\n\nA opção 'Bloquear uso' impede que suas faixas sejam reproduzidas.\n\nQuando a opção 'Monitorar uso' estiver ativa, você receberá estatísticas sobre o uso/reprodução de suas faixas.",
  //             },
  //           ],
  //         },
  //         urlInStore: '',
  //       },
  //     ],
  //   };
  //   return of(data as unknown as GetReleaseV2DistributionReleaseIdStores);
  // }

  /**
   * @deprecated The method should not be used
   */
  getStoreV2Find() {
    return this._http.get<StoreDeprecated>(
      `${this.apiUrl}/api/distribution/store/v2/find?active=false`,
    );
  }

  private getTeamId() {
    return this.mzicArtistLocalService.getTeamId();
  }
}
