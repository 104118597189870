import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import {
  MediaResourceDownloadTrack,
  MediaResourceUploadTrack,
  ResponseInterface,
} from '@mzic/mzic-interfaces';
import { Observable } from 'rxjs';
import { MzicArtistLocalService } from '../../mzic-artist-local/mzic-artist-local.service';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private apiUrl = environment.apiUrl;

  headers = new HttpHeaders({
    Accept: '*/*',
    'TEAM-ID': this.mzicArtistLocalService.getTeamId(),
  });

  constructor(
    private readonly _http: HttpClient,
    private mzicArtistLocalService: MzicArtistLocalService,
  ) {}

  uploadImage(
    file: File,
    cover = false,
  ): Observable<ResponseInterface<string>> {
    const url = `${this.apiUrl}/api/distribution/media/v2/upload/image?cover=${cover}`;
    const formData = new FormData();
    formData.append('file', file);
    return this._http.post<ResponseInterface<string>>(url, formData, {
      headers: this.headers,
    });
  }

  downloadTrackAudioFile(
    trackId: number,
  ): Observable<MediaResourceDownloadTrack> {
    return this._http.get<MediaResourceDownloadTrack>(
      `${this.apiUrl}/api/distribution/media/v2/download/track?trackId=${trackId}`,
      { headers: this.headers },
    );
  }

  uploadTrackAudioFile(
    file: File,
    trackId?: number,
  ): Observable<MediaResourceUploadTrack> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    // Se passar o TrackID então atualiza o aúdio
    return this._http.post<MediaResourceUploadTrack>(
      `${this.apiUrl}/api/distribution/media/v2/upload/track${trackId ? `?trackId=${trackId}` : ''}`,
      formData,
      { headers: this.headers },
    );
  }
}
