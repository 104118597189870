import { ApiResponse } from '../shared/api-response.interface';

export interface GetUserProfile extends ApiResponse {
  data: GetUserProfileData;
}

export interface GetUserProfileData {
  username: string;
  email: string;
  profilePhoto: string;
  backgroundPhoto: string;
  walletId: number;
  createdAt: string;
  fullName: string;
  phoneNumber: string;
  chainWallet: string;
  cognito: string;
  userId: number;
  walletType: string;
  phoneNumberValidate: boolean;
  region: string;
  gatewayType: string;
  birthDate: string;
  currencyType: string;
  verified: boolean;
  profilePhotoUrl?: string;
}

export function createEmptyUserProfile(): GetUserProfileData {
  return {
    username: '',
    email: '',
    profilePhoto: '',
    backgroundPhoto: '',
    walletId: 0,
    createdAt: '',
    fullName: '',
    phoneNumber: '',
    chainWallet: '',
    cognito: '',
    userId: 0,
    walletType: '',
    phoneNumberValidate: false,
    region: '',
    gatewayType: '',
    birthDate: '',
    currencyType: '',
    verified: false,
    profilePhotoUrl: '',
  };
}
