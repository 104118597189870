import { environment } from '@mzic/mzic-environments';
import { ConfirmationTypeEnum, MeanTypeEnum } from '../enums';

const version = 'v2';

const apiInstagram = environment.apiInstagram;
const urlCallbackInstagram = encodeURIComponent(
  environment.urlCallbackInstagram,
);
const clientIdInstagram = environment.clientIdInstagram;

export class API_URL {
  static apiUrl = environment.apiUrl;
  static readonly USER_ARTIST_SIGN_IN = `${this.apiUrl}/api/backoffice/user/${version}/sign-in`;
  static readonly CONFIRM_EMAIL = `${this.apiUrl}/api/backoffice/user/${version}/confirm-email`;
  static readonly RESEND_VERIFICATION_CODE = `${this.apiUrl}/api/backoffice/user/${version}/resend-verification-code`;
  static readonly USER_ARTIST_SIGN_OUT = `${this.apiUrl}/api/backoffice/user/${version}/sign-out`;

  static readonly SEND_RESET_PASSWORD_CODE = `${this.apiUrl}/api/backoffice/user/${version}/forgot-password`;
  static readonly CONFIRM_RESET_PASSWORD_CODE = `${this.apiUrl}/api/backoffice/user/${version}/reset-password`;

  static readonly MUSIC_RESOURCE_MY_SONGS_COPYRIGHT = `${this.apiUrl}/api/backoffice/music/${version}/mySongs/copyright`;
  static readonly MUSIC_RESOURCE_CALENDAR = `${this.apiUrl}/api/backoffice/music/${version}/mySongs/calendar`;
  static readonly MUSIC_RESOURCE_TRACK = `${this.apiUrl}/api/backoffice/distribution/track`;

  static readonly SOCIAL_ARTISTS_CREATE = `${this.apiUrl}/api/backoffice/social/${version}/create`;
  static readonly SOCIAL_ARTISTS_SEARCH = `${this.apiUrl}/api/backoffice/social/${version}/search`;
  static readonly SOCIAL_ARTISTS_RETRIEVE = `${this.apiUrl}/api/backoffice/social/${version}/retrieve`;

  static readonly TEAMS = `${this.apiUrl}/api/backoffice/wallet-team/${version}/my-teams`;
  static readonly RESEND_CODE = `${this.apiUrl}/api/backoffice/user/${version}/resend-verification-code`;
  static readonly AUTH_INSTAGRAM = `${apiInstagram}?client_id=${clientIdInstagram}&redirect_uri=${urlCallbackInstagram}&scope=user_profile,user_media&response_type=code`;

  static readonly USER_AGREE = `${this.apiUrl}/api/backoffice/user/${version}/agree`;
  static readonly USER_SIGN_UP = `${this.apiUrl}/api/backoffice/user/${version}/sign-up`;
  static readonly MEDIA_UPLOAD_IMAGE = `${this.apiUrl}/api/distribution/media/${version}/upload/image`;

  static readonly CONFIRM_CODE = (
    meanType: MeanTypeEnum,
    confirmationType: ConfirmationTypeEnum,
  ): string =>
    `${this.apiUrl}/api/backoffice/user/${version}/confirm/${meanType}/${confirmationType}`;

  static readonly RESEND_CONFIRM_CODE = (
    meanType: MeanTypeEnum,
    confirmationType: ConfirmationTypeEnum,
  ): string =>
    `${this.apiUrl}/api/backoffice/user/${version}/resend-confirmation/${meanType}/${confirmationType}`;
}
