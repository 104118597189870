import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import {
  ArtistTypeResourceData,
  ArtistTypesResourceData,
  ResponseInterface,
} from '@mzic/mzic-interfaces';
import { Observable } from 'rxjs';
import { MzicArtistLocalService } from '../../mzic-artist-local/mzic-artist-local.service';

@Injectable({
  providedIn: 'root',
})
export class ArtistTypeService {
  private apiUrl = environment.apiUrl;

  headers = new HttpHeaders({
    Accept: '*/*',
    'TEAM-ID': this.mzicArtistLocalService.getTeamId(),
  });

  constructor(
    private readonly _http: HttpClient,
    private mzicArtistLocalService: MzicArtistLocalService,
  ) {}

  getArtistTypeIdByRoleId(
    roleId: number,
  ): Observable<ResponseInterface<ArtistTypesResourceData>> {
    return this._http.get<ResponseInterface<ArtistTypesResourceData>>(
      `${this.apiUrl}/api/distribution/artist-type/v2/find?lsRoleId=${roleId}`,
      { headers: this.headers },
    );
  }

  getArtistTypes(
    priorities: number[] = [],
    sort = '',
  ): Observable<ResponseInterface<ArtistTypeResourceData>> {
    return this._http.get<ResponseInterface<ArtistTypeResourceData>>(
      `${this.apiUrl}/api/distribution/artist-type/v2/find${priorities.length > 0 ? `?priority=${priorities.join(',')}` : ''}${sort !== '' ? `&sort=${sort}` : ''}&size=400`,
      { headers: this.headers },
    );
  }
}
