import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseResetPassword } from '@mzic/mzic-interfaces';
import { API_URL, UrlParams } from '@mzic/mzic-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MzicForgotPasswordService {
  constructor(private readonly _http: HttpClient) {}

  public sendResetPasswordCode(payload: {
    email: string;
    username?: string;
  }): Observable<void> {
    const query = UrlParams(payload);
    return this._http.post<void>(API_URL.SEND_RESET_PASSWORD_CODE + query, {});
  }

  public confirmResetPasswordCode(payload: {
    email: string;
    username?: string;
    newPassword: string;
    confirmationCode: string;
  }): Observable<ResponseResetPassword> {
    const query = UrlParams(payload);
    return this._http.post<ResponseResetPassword>(
      API_URL.CONFIRM_RESET_PASSWORD_CODE + query,
      {},
    );
  }
}
