<ng-container *transloco="let t">
  <section class="wrapper">
    <header class="header">
      <h1 class="header__title">
        {{ isTerms ? t('terms') : t('privacy') }}

        <span class="icon-desktop">
          <img src="assets/icons/right-upward-arrow.svg" />
        </span>
        <span class="icon-mobile">
          <img src="assets/icons/right-downward-arrow.svg" />
        </span>
      </h1>
      <p class="header__subtitle">{{ t('last_update') }}</p>
    </header>

    <div class="scroll-wrapper">
      <!-- Termos pt-br -->
      @if(isPortuguese && isTerms) {
      <mzic-terms-pt-br></mzic-terms-pt-br>
      }

      <!-- Termos en -->
      @if(!isPortuguese && isTerms) {
      <mzic-terms-en></mzic-terms-en>
      }

      <!-- Privacidade pt-br -->
      @if(isPortuguese && !isTerms) {
      <mzic-privacy-pt-br></mzic-privacy-pt-br>
      }

      <!-- Privacidade en -->
      @if(!isPortuguese && !isTerms) {
      <mzic-privacy-en></mzic-privacy-en>
      }
    </div>
  </section>
</ng-container>
