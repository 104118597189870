import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import { AlbumsDTO, ResponseInterface } from '@mzic/mzic-interfaces';
import { map } from 'rxjs';
import { MzicArtistLocalService } from '../../mzic-artist-local/mzic-artist-local.service';

@Injectable({
  providedIn: 'root',
})
export class SpotifyService {
  private apiUrl = environment.apiUrl;

  headers = new HttpHeaders({
    Accept: '*/*',
    'TEAM-ID': this.mzicArtistLocalService.getTeamId(),
  });

  constructor(
    private readonly _http: HttpClient,
    private mzicArtistLocalService: MzicArtistLocalService,
  ) {}

  getAlbums(uuid: string, limit = 20, offset = 0) {
    return this._http
      .get<
        ResponseInterface<AlbumsDTO>
      >(`${this.apiUrl}/api/distribution/spotify/v1/albums/${uuid}?limit=${limit}&offset=${offset}`, { headers: this.headers })
      .pipe(map((response) => response.data.releases));
  }
}
